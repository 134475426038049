import { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import { BarChartIcon, BlendIcon, LayersIcon } from "lucide-react";

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "@/view/components";
import { useAnalytics } from "@/view/providers/analytics-provider";
import { cn } from "@/view/utils";

export type ChartType = "single" | "combined" | "compared";

export function ChartTypeSelector({
  options,
  value,
  onChange,
}: {
  options: Array<ChartType>;
  value: ChartType;
  onChange: (value: ChartType) => void;
}) {
  const analytics = useAnalytics();
  const { i18n } = useLingui();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          analyticsEvent="chart_type_selector_clicked"
          className={cn(
            "border rounded-lg border-brand-gray-2",
            "bg-brand-white hover:bg-brand-gray-2",
            "font-semibold text-brand-gray-5",
            "flex gap-2 items-center"
          )}
        >
          {getIcon(value)}
          {getName(value, i18n)}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="min-w-[240px] rounded-lg">
        <DropdownMenuRadioGroup
          value={value}
          onValueChange={(value) => {
            analytics.collect("chart_type_selector_value_changed", { value });
            onChange(value as ChartType);
          }}
        >
          {options.map((option) => (
            <DropdownMenuRadioItem
              key={option}
              value={option}
              className="flex gap-2 items-center"
            >
              {getIcon(option)}
              {getName(option, i18n)}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function getIcon(chartType: ChartType) {
  switch (chartType) {
    case "single":
      return <BarChartIcon className="w-4 text-brand-blue-1" />;
    case "combined":
      return <LayersIcon className="w-4 text-brand-blue-1" />;
    case "compared":
      return <BlendIcon className="w-4 text-brand-blue-1" />;
  }
}

function getName(chartType: ChartType, i18n: I18n) {
  switch (chartType) {
    case "single":
      return i18n.t("Standard view");
    case "combined":
      return i18n.t("Combine view");
    case "compared":
      return i18n.t("Compare view");
  }
}
