import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Pagination } from "@/domain/common/pagination";
import { DateRangeFilter } from "@/domain/common/time-filter";
import { ShiftId } from "@/domain/shifts";
import { Station } from "@/domain/station";
import { useAnalytics } from "@/view/providers/analytics-provider";

import { DateRangeSelect } from "../line-id/date-range-select";
import { useSelectedLine } from "../line-id/selected-line-provider";
import { ShiftSelect } from "../line-id/shift-select";
import {
  encodeDateRangeToSearchParams,
  parseDateRangeFromSearchParams,
  SelectedDateRangeContext,
} from "../line-id/use-selected-date-range";
import {
  encodeShiftIdsToSearchParams,
  parseShiftIdsFromSearchParams,
  SelectedShiftIdsContext,
} from "../line-id/use-selected-shift-ids";
import {
  DurationRange,
  DurationRangeContext,
  encodeDurationRangeToSearchParams,
  parseDurationRangeFromSearchParams,
} from "../line-id-video-library/use-duration-range";
import {
  PaginationContext,
  parsePaginationFromSearchParams,
} from "../line-id-video-library/use-pagination";
import { DurationRangeSelect } from "./duration-range-select";
import {
  SelectedStationDetailsContext,
  useSelectedStationDetails,
} from "./selected-station-provider";
import {
  DrawerTab,
  encodeSelectedDrawerTabToSearchParams,
  parseSelectedDrawerTabFromSearchParams,
  SelectedDrawerTabContext,
} from "./use-selected-tab";

type StationReportingFilters = {
  station: Station;
  shiftIds: Array<ShiftId>;
  dateRange: DateRangeFilter;
  pagination: Pagination;
  durationRange: DurationRange;
  drawerTab: DrawerTab;
};

type StationReportingFilterActions = {
  selectShifts: (shiftIds: Array<ShiftId>) => void;
  selectDateRange: (dateRange: DateRangeFilter) => void;
  selectDurationRange: (durationRange: DurationRange, tab?: DrawerTab) => void;
  selectPage: (page: number) => void;
  selectDrawerTab: (tab: DrawerTab) => void;
};

const StationReportingFilterActionsContext =
  createContext<StationReportingFilterActions | null>(null);

// eslint-disable-next-line react-refresh/only-export-components
export function useStationReportingFilterActions() {
  const actions = useContext(StationReportingFilterActionsContext);
  if (!actions) {
    throw new Error(
      "useStationReportingFilterActions must be used inside StationReportingFilterActionsProvider"
    );
  }
  return actions;
}

export function StationReportingFiltersProvider({
  children,
}: PropsWithChildren) {
  const analytics = useAnalytics();
  const line = useSelectedLine();
  const station = useSelectedStationDetails();
  const [state, setState] = useState<StationReportingFilters>(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return {
      station,
      drawerTab: parseSelectedDrawerTabFromSearchParams(searchParams),
      durationRange: parseDurationRangeFromSearchParams(searchParams),
      shiftIds: parseShiftIdsFromSearchParams(searchParams, line)!,
      dateRange: parseDateRangeFromSearchParams(searchParams),
      pagination: parsePaginationFromSearchParams(searchParams),
    };
  });

  useEffect(() => {
    // synchronise with lineId from the URL
    if (state.station.id === station.id) return;
    setState((prev) => ({
      station,
      durationRange: null,
      drawerTab: prev.drawerTab,
      shiftIds: prev.shiftIds,
      dateRange: prev.dateRange,
      pagination: {
        ...prev.pagination,
        offset: 0,
      },
    }));
  }, [station, state.station.id]);

  // TODO: turn this into a generic "useSynchronizeUrl" custom hook
  // when the "useEffectEvent" hook is released in a stable version of React
  // => https://react.dev/learn/separating-events-from-effects#declaring-an-effect-event
  useEffect(() => {
    // synchronise with URL
    let searchParams = new URLSearchParams();
    searchParams = encodeShiftIdsToSearchParams(searchParams, state.shiftIds);
    searchParams = encodeDateRangeToSearchParams(searchParams, state.dateRange);
    searchParams = encodeDurationRangeToSearchParams(
      searchParams,
      state.durationRange
    );
    searchParams = encodeSelectedDrawerTabToSearchParams(
      searchParams,
      state.drawerTab
    );

    window.history.replaceState(
      null,
      "",
      `${window.location.pathname}?${searchParams}`
    );

    // send analytics event
    analytics.collect("filters_changed");
  }, [state, analytics]);

  const selectedStation = useMemo(() => state.station, [state.station]);
  const selectedShiftIds = useMemo(() => state.shiftIds, [state.shiftIds]);
  const selectedDateRange = useMemo(() => state.dateRange, [state.dateRange]);
  const selectedDrawerTab = useMemo(() => state.drawerTab, [state.drawerTab]);
  const selectedDurationRange = useMemo(
    () => state.durationRange,
    [state.durationRange]
  );
  const pagination = useMemo(() => state.pagination, [state.pagination]);
  const actions = useMemo<StationReportingFilterActions>(() => {
    return {
      selectShifts(shiftIds) {
        setState((prev) => ({
          ...prev,
          shiftIds,
          pagination: { ...prev.pagination, offset: 0 },
        }));
      },
      selectDateRange(dateRange) {
        setState((prev) => ({
          ...prev,
          dateRange,
          pagination: { ...prev.pagination, offset: 0 },
        }));
      },
      selectDurationRange(range, tab) {
        setState((prev) => ({
          ...prev,
          drawerTab: tab ?? prev.drawerTab,
          durationRange: range,
          pagination: { ...prev.pagination, offset: 0 },
        }));
      },
      selectDrawerTab(tab) {
        setState((prev) => ({ ...prev, drawerTab: tab }));
      },
      selectPage(page) {
        setState((prev) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            offset: (page - 1) * prev.pagination.limit,
          },
        }));
      },
    };
  }, []);

  return (
    // provide the station context again, so that the "selectedStation" comes
    // from state value, which prevents double rerenders caused by station change
    <SelectedStationDetailsContext.Provider value={selectedStation}>
      <SelectedShiftIdsContext.Provider value={selectedShiftIds}>
        <SelectedDateRangeContext.Provider value={selectedDateRange}>
          <PaginationContext.Provider value={pagination}>
            <DurationRangeContext.Provider value={selectedDurationRange}>
              <SelectedDrawerTabContext.Provider value={selectedDrawerTab}>
                <StationReportingFilterActionsContext.Provider value={actions}>
                  {children}
                </StationReportingFilterActionsContext.Provider>
              </SelectedDrawerTabContext.Provider>
            </DurationRangeContext.Provider>
          </PaginationContext.Provider>
        </SelectedDateRangeContext.Provider>
      </SelectedShiftIdsContext.Provider>
    </SelectedStationDetailsContext.Provider>
  );
}

export function StationReportingFilters() {
  const actions = useStationReportingFilterActions();
  return (
    <>
      <ShiftSelect onChange={actions.selectShifts} />
      <DateRangeSelect onChange={actions.selectDateRange} />
    </>
  );
}

export function VideosReportingFilters() {
  const actions = useStationReportingFilterActions();
  return (
    <>
      <DurationRangeSelect onChange={actions.selectDurationRange} />
      {}
      <ShiftSelect onChange={actions.selectShifts} />
      <DateRangeSelect onChange={actions.selectDateRange} />
    </>
  );
}
