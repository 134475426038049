import { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import { EllipsisVerticalIcon } from "lucide-react";
import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { ShiftVariantTargetType } from "@/domain/shifts";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/view/components";
import { paths } from "@/view/routes";
import { cn } from "@/view/utils";

import { useSelectedLine } from "../line-id/selected-line-provider";
import { useTargetsOverrideActions } from "./targets-override-form-provider";

export function LineWidgetDropdown({
  targetType,
  className,
}: {
  targetType: ShiftVariantTargetType;
  className?: string;
}) {
  const [open, setOpen] = useState(false);
  return (
    <DropdownMenu
      open={open}
      onOpenChange={(newOpen) => {
        if (newOpen) setOpen(newOpen);
      }}
    >
      <DropdownMenuTrigger asChild>
        <Button
          analyticsEvent="target_override_dropdown_clicked"
          className={cn("px-1 h-auto bg-transparent text-brand-gray-3", {
            "hover:text-brand-gray-5": !open,
            "text-brand-blue-1": open,
            className,
          })}
        >
          <EllipsisVerticalIcon />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className="min-w-[240px] rounded-lg"
        onInteractOutside={() => setOpen(false)}
      >
        <OverrideTargetsItem targetType={targetType} />
        <GoToShiftSettingsItem />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function OverrideTargetsItem({
  targetType,
}: {
  targetType: ShiftVariantTargetType;
}) {
  const { i18n } = useLingui();
  const actions = useTargetsOverrideActions();
  return (
    <DropdownMenuItem
      className="hover:bg-brand-gray-1 py-2"
      onClick={() => actions.select({ targetType })}
    >
      {i18n.t("Override {type}", {
        type: getTtargetTypeLabel(targetType, i18n),
      })}
    </DropdownMenuItem>
  );
}

function GoToShiftSettingsItem() {
  const { i18n } = useLingui();
  const line = useSelectedLine();
  const navigate = useNavigate();
  return (
    <DropdownMenuItem
      className="hover:bg-brand-gray-1 py-2"
      onClick={() =>
        navigate(
          generatePath(paths.lineSettingsShiftsPath, { lineId: line.id })
        )
      }
    >
      {i18n.t("Go to shift settings")}
    </DropdownMenuItem>
  );
}

function getTtargetTypeLabel(targetType: ShiftVariantTargetType, i18n: I18n) {
  switch (targetType) {
    case "output":
      return i18n.t("Target output").toLowerCase();
    case "persons":
      return i18n.t("Assigned people").toLowerCase();
    case "cycleTime":
      return i18n.t("Target tact time").toLowerCase();
  }
}
