import { z } from "zod";

import { lineIdSchema } from "./line";

export const tagIdSchema = z.string().brand<"TagId">();
const tagTypeSchema = z.enum(["System", "User", "CV"]);

export const tagSchema = z.object({
  id: tagIdSchema,
  name: z.string(),
  color: z.string(),
  type: tagTypeSchema,
});

const getAllTagsFiltersSchema = z.object({
  lineId: lineIdSchema,
});

const createTagParamsSchema = z.object({
  lineId: lineIdSchema,
  name: z.string().min(1),
});

export type TagId = z.infer<typeof tagIdSchema>;
export type TagType = z.infer<typeof tagTypeSchema>;
export type Tag = z.infer<typeof tagSchema>;
export type GetAllTagsFilters = z.infer<typeof getAllTagsFiltersSchema>;
export type CreateTagParamsSchema = z.infer<typeof createTagParamsSchema>;

type TagsByType = Record<TagType, Array<Tag>>;
type TagsById = Record<TagId, Tag>;

export type TagStore = {
  tagsById: TagsById;
  tagsByType: TagsByType;
  allTagsIds: Array<TagId>;
};

type GetAllowedTags = (store: TagStore) => Array<Tag>;

export function getValidTagIds(
  tagIds: Array<string>,
  allowedTagsIds: Set<TagId>
): Array<TagId> {
  const validTagIds: Array<TagId> = [];
  for (const tagId of tagIds) {
    const result = tagIdSchema.safeParse(tagId);
    if (!result.success) continue;
    if (!allowedTagsIds.has(result.data)) continue;
    validTagIds.push(result.data);
  }
  // only return the last 3 valid tags
  return validTagIds.slice(-3);
}

export function getAllowedTagIds(allowedTags: Array<Tag>): Set<TagId> {
  return new Set(allowedTags.map((tag) => tag.id));
}

export function getTagsForTypes(types: Array<TagType>): GetAllowedTags {
  return (store) => types.flatMap((type) => store.tagsByType[type]);
}

export function normalizeAllTagsResponse(tags: Array<Tag>): TagStore {
  const allTagsIds: Array<TagId> = [];
  const tagsById: TagsById = {};
  const tagsByType: TagsByType = {
    System: [],
    User: [],
    CV: [],
  };

  for (const tag of tags) {
    allTagsIds.push(tag.id);
    tagsById[tag.id] = tag;
    tagsByType[tag.type].push(tag);
  }

  return {
    tagsById,
    tagsByType,
    allTagsIds,
  };
}
