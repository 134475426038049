import { useSuspenseQuery } from "@tanstack/react-query";
import { createContext, PropsWithChildren, useContext } from "react";

import { DateRangeFilter } from "@/domain/common/time-filter";
import { LineId } from "@/domain/line";
import { ShiftId } from "@/domain/shifts";
import { LineStatistics } from "@/domain/statistics";
import { TagId } from "@/domain/tag";
import { useApiClient } from "@/view/providers/api-client-provider";

import { useSelectedLine } from "../line-id/selected-line-provider";
import { useSelectedDateRange } from "../line-id/use-selected-date-range";
import { useSelectedShiftIds } from "../line-id/use-selected-shift-ids";
import { useSelectedTagIds } from "../line-id/use-selected-tag-ids";

const LineStatisticsContext = createContext<LineStatistics | null>(null);

// eslint-disable-next-line react-refresh/only-export-components
export function useLineStatistics() {
  const lineStatistics = useContext(LineStatisticsContext);
  if (!lineStatistics) {
    throw new Error(
      "useLineStatistics must be used within a LineStatisticsProvider"
    );
  }
  return lineStatistics;
}

// eslint-disable-next-line react-refresh/only-export-components
export function getLineStatisticsKey(
  lineId: LineId,
  dateRange: DateRangeFilter,
  shiftIds: Array<ShiftId>,
  tagIds: Array<TagId>
) {
  return ["line-statistics", lineId, dateRange, shiftIds, tagIds] as const;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useLineStatisticsParams(): [
  ReturnType<typeof useSelectedLine>,
  ReturnType<typeof useSelectedShiftIds>,
  ReturnType<typeof useSelectedTagIds>,
  ReturnType<typeof useSelectedDateRange>,
] {
  const line = useSelectedLine();
  const shiftIds = useSelectedShiftIds();
  const tagIds = useSelectedTagIds();
  const dateRange = useSelectedDateRange();
  return [line, shiftIds, tagIds, dateRange];
}

/**
 * Provides line statistics down the component tree.
 */
export function LineStatisticsProvider({ children }: PropsWithChildren) {
  const apiClient = useApiClient();
  const [line, shiftIds, tagIds, dateRange] = useLineStatisticsParams();
  const query = useSuspenseQuery({
    queryKey: getLineStatisticsKey(line.id, dateRange, shiftIds, tagIds),
    queryFn: ({ signal }) => {
      return apiClient.getLineStatistics(
        { lineId: line.id, dateRange, shiftIds, tagIds },
        { signal }
      );
    },
    staleTime: Infinity,
    refetchInterval: 1000 * 60 * 30, // Half an hour
    refetchIntervalInBackground: true,
  });

  return (
    <LineStatisticsContext.Provider value={query.data}>
      {children}
    </LineStatisticsContext.Provider>
  );
}
