import { useQuery } from "@tanstack/react-query";
import { contourDensity } from "d3-contour";
import { geoPath } from "d3-geo";
import { scaleLinear, scaleSequentialLog } from "d3-scale";
import { interpolateTurbo } from "d3-scale-chromatic";
import { memo, useMemo } from "react";

import type { CameraId, Heatmap } from "@/domain/areas-of-interests";
import { useSelectedDateRange } from "@/view/pages/line-id/use-selected-date-range";
import { useSelectedShiftIds } from "@/view/pages/line-id/use-selected-shift-ids";
import { apiClient } from "@/view/routes";

const getXValue = (d: Heatmap[number]) => d[0];
const getYValue = (d: Heatmap[number]) => d[1];
const getIntensityValue = (d: Heatmap[number]) => d[2];

function getMinMaxValues(data: Heatmap) {
  const results = {
    minIntensity: Number.MAX_SAFE_INTEGER,
    maxIntensity: Number.MIN_SAFE_INTEGER,
    maxX: Number.MIN_SAFE_INTEGER,
    maxY: Number.MIN_SAFE_INTEGER,
  };

  for (const d of data) {
    const x = getXValue(d);
    const y = getYValue(d);
    const intensity = getIntensityValue(d);

    results.minIntensity = Math.min(results.minIntensity, intensity);
    results.maxIntensity = Math.max(results.maxIntensity, intensity);
    results.maxX = Math.max(results.maxX, x);
    results.maxY = Math.max(results.maxY, y);
  }

  return results;
}

export const WalkingRoutesHeatmap = memo(function WalkingRoutesHeatmap({
  cameraId,
  height,
  width,
  opacity,
}: {
  cameraId: CameraId;
  height: number;
  width: number;
  opacity: number;
}) {
  const dateRange = useSelectedDateRange();
  const selectedShifts = useSelectedShiftIds();
  const { data } = useQuery({
    queryKey: ["heatmap", cameraId, dateRange, selectedShifts],
    queryFn: ({ signal }) => {
      return apiClient.getHeatmapByCameraId(
        { dateRange, cameraId, shiftsIds: selectedShifts },
        { signal }
      );
    },
  });

  const heatmapData = useMemo(() => {
    if (!data) return null;

    const { minIntensity, maxIntensity, maxX, maxY } = getMinMaxValues(data);

    const x = scaleLinear().domain([0, maxX]).range([0, width]).clamp(true);
    /**
     * we get [x, y, intensity] from the backend
     * CS algorithm coordinates are from top left corner
     * so we need to invert the y axis ([0, height] instead of [height, 0] as usual as svg also uses top left corner as origin)
     */
    const y = scaleLinear().domain([0, maxY]).range([0, height]).clamp(true);

    const colorScale = scaleSequentialLog()
      .domain([minIntensity, maxIntensity])
      .range([0, 1])
      .clamp(true);

    const densityData = contourDensity<Heatmap[number]>()
      .x((d) => x(getXValue(d)))
      .y((d) => y(getYValue(d)))
      .weight(getIntensityValue)
      .size([width, height])
      .bandwidth(15)
      .thresholds(80)(data);

    return densityData.map((d) => ({
      path: geoPath()(d)!,
      color: interpolateTurbo(colorScale(d.value)),
    }));
  }, [data, width, height]);

  if (!heatmapData) return null;

  return (
    <g>
      {heatmapData.map((d, i) => (
        <path key={i} d={d.path} fill={d.color} fillOpacity={opacity} />
      ))}
    </g>
  );
});
