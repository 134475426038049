import { PropsWithChildren } from "react";

import {
  useVirtualSensorQuery,
  VirtualSensorContext,
} from "./use-virtual-sensor";

/**
 * Provides bird eye view (walkng routes and heatmap) down the component tree.
 */
export function VirtualSensorProvider({ children }: PropsWithChildren) {
  const query = useVirtualSensorQuery();
  return (
    <VirtualSensorContext.Provider value={query.data}>
      {children}
    </VirtualSensorContext.Provider>
  );
}
