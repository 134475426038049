import { isBefore, isToday, subMonths } from "date-fns";
import { useState } from "react";

import {
  DateRangeFilter,
  dateRangeOptions,
  findDateOptionValueByRange,
  findNextDateRange,
  findPreviousDateRange,
  today,
} from "@/domain/common/time-filter";
import { localeForDateFns } from "@/i18n";
import {
  Button,
  Calendar,
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/view/components";
import { cn, formatDateRange, getDateRangeLabel } from "@/view/utils";

import { useSelectedDateRange } from "./use-selected-date-range";

export function DateRangeSelect({
  onChange: onClose,
}: {
  onChange: (value: DateRangeFilter) => void;
}) {
  const initialValue = useSelectedDateRange();
  const [open, setOpen] = useState(false);
  const [value, onChange] = useState(initialValue);
  const selectedDateOption = findDateOptionValueByRange(value.values);
  const dateRangeLabel = selectedDateOption
    ? getDateRangeLabel(selectedDateOption)
    : formatDateRange(value.values);

  return (
    <div className="flex gap-1">
      <Button
        analyticsEvent="date_range_changed"
        className={cn(
          "border-2 rounded-lg border-brand-gray-2",
          "bg-brand-white hover:bg-brand-gray-2",
          "font-semibold text-brand-gray-5"
        )}
        onClick={() => {
          const newValue = {
            values: findPreviousDateRange(value.values),
            excluded: value.excluded,
          };
          onChange(newValue);
          onClose(newValue);
        }}
      >
        <ChevronLeftIcon />
      </Button>
      <Popover
        open={open}
        onOpenChange={(open) => {
          setOpen(open);
          if (!open) onClose(value);
        }}
      >
        <PopoverTrigger asChild>
          <Button
            analyticsEvent="time_filter_clicked"
            className={cn(
              "border-2 rounded-lg border-brand-gray-2",
              "bg-brand-white hover:bg-brand-gray-2",
              "font-semibold text-brand-gray-5"
            )}
          >
            <span className="select-none flex gap-2 justify-between items-center">
              <CalendarIcon />
              {dateRangeLabel}
            </span>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-fit" align="end">
          {open && (
            <RangeCalendar
              value={value}
              onChange={(dateRange) => onChange(dateRange)}
            />
          )}
        </PopoverContent>
      </Popover>
      <Button
        analyticsEvent="date_range_changed"
        className={cn(
          "border-2 rounded-lg border-brand-gray-2",
          "bg-brand-white hover:bg-brand-gray-2",
          "font-semibold text-brand-gray-5"
        )}
        disabled={isToday(value.values.end)}
        onClick={() => {
          const newValue = {
            values: findNextDateRange(value.values),
            excluded: value.excluded,
          };
          onChange(newValue);
          onClose(newValue);
        }}
      >
        <ChevronRightIcon />
      </Button>
    </div>
  );
}

function RangeCalendar({
  value,
  onChange,
}: {
  value: DateRangeFilter;
  onChange: (range: DateRangeFilter) => void;
}) {
  const [range, setRange] = useState<{ start: Date; end?: Date }>(value.values);
  const [month, setMonth] = useState<Date>(subMonths(value.values.end, 1));

  function handleDayClick(date: Date) {
    let start = value.values.start;
    let end = value.values.end;
    if (range.end) {
      start = date;
      end = date;
      setRange({ start: date, end: undefined });
    } else {
      const isBeforeStart = isBefore(date, range.start);
      start = isBeforeStart ? date : range.start;
      end = isBeforeStart ? range.start : date;
      setRange({ start, end });
    }
    onChange({
      excluded: value.excluded,
      values: { start, end },
    });
  }

  function handleWeekdayClick(date: Date) {
    const dayOfWeek = date.getDay();
    const newExcluded = value.excluded.includes(dayOfWeek)
      ? value.excluded.filter((it) => it !== dayOfWeek)
      : [...value.excluded, dayOfWeek];
    onChange({
      ...value,
      excluded: newExcluded,
    });
  }

  return (
    <div className="flex gap-4">
      <ul className="list-none">
        {dateRangeOptions.map((option) => (
          <li key={option.value}>
            <Button
              analyticsEvent="date_range_changed"
              className={cn(
                "bg-transparent hover:bg-brand-gray-2",
                "font-semibold text-brand-gray-5",
                "justify-start min-w-[160px] rounded-md"
              )}
              onClick={() => {
                setRange(option.range);
                setMonth(subMonths(option.range.end, 1));
                onChange({
                  excluded: value.excluded,
                  values: option.range,
                });
              }}
            >
              {getDateRangeLabel(option.value)}
            </Button>
          </li>
        ))}
      </ul>
      <div className="bg-brand-gray-2 w-[1px]" />
      <Calendar
        mode="range"
        numberOfMonths={2}
        weekStartsOn={1}
        month={month}
        onMonthChange={setMonth}
        disabled={[{ dayOfWeek: value.excluded }, { after: today }]}
        selected={{ from: range.start, to: range.end }}
        onDayClick={handleDayClick}
        onWeekdayClick={handleWeekdayClick}
        locale={localeForDateFns()}
      />
    </div>
  );
}
