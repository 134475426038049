import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { createContext, useContext } from "react";

import { BaseError } from "@/domain/common/errors";
import { normalizeAllTagsResponse, Tag, TagStore } from "@/domain/tag";
import { useApiClient } from "@/view/providers/api-client-provider";

import { useSelectedLine } from "./selected-line-provider";

export const TagsContext = createContext<TagStore | null>(null);

export function useTags() {
  const ctx = useContext(TagsContext);
  if (!ctx) {
    throw new Error("useTags must be used within a TagsProvider");
  }
  return ctx;
}

export function useTagsQuery() {
  const options = useTagsQueryOptions();
  return useSuspenseQuery(options);
}

export const getTagsQueryKey = (lineId: string) =>
  ["all-tags", lineId] as const;

function useTagsQueryOptions() {
  const apiClient = useApiClient();
  const line = useSelectedLine();
  return queryOptions<Array<Tag>, BaseError, TagStore>({
    // We use the lineId to refetch the tags when the line changes,
    // which is needed for admin accounts to not mix up tags from
    // different customers.
    queryKey: getTagsQueryKey(line.id),
    queryFn: () => apiClient.getAllTags({ lineId: line.id }),
    staleTime: Infinity,
    select: normalizeAllTagsResponse,
  });
}
