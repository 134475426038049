import * as DialogPrimitive from "@radix-ui/react-dialog";
import { RowsIcon, XIcon } from "lucide-react";
import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useMemo,
} from "react";

import { cn } from "@/view/utils";

import { Button } from "./button";
import { Dialog, DialogOverlay, DialogPortal } from "./dialog";

const DrawerContext = createContext<{ onClose: () => void } | null>(null);

function useDrawerContext() {
  const value = useContext(DrawerContext);
  if (!value) {
    throw new Error(
      "useDrawerContext must be used inside DrawerContext.Provider"
    );
  }
  return value;
}

export function OverviewDrawer({
  onClose,
  children,
}: PropsWithChildren<{
  onClose: () => void;
}>) {
  const contextValue = useMemo(() => ({ onClose }), [onClose]);

  return (
    <Dialog open onOpenChange={(open) => !open && onClose()}>
      <DialogPortal>
        <DialogOverlay className="z-20 opacity-20" />

        <DialogPrimitive.Content
          onClick={(e) => e.stopPropagation()}
          className={cn(
            "fixed z-30 right-0 top-0 bottom-0 overflow-hidden",
            "flex flex-col min-w-[480px] max-w-6xl w-full",
            "bg-brand-white shadow-lg transition ease-in-out",
            "data-[state=open]:animate-in data-[state=open]:duration-300 data-[state=open]:slide-in-from-right",
            "data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=closed]:slide-out-to-right"
          )}
        >
          <DrawerContext.Provider value={contextValue}>
            {children}
          </DrawerContext.Provider>
        </DialogPrimitive.Content>
      </DialogPortal>
    </Dialog>
  );
}

export function OverviewDrawerHeader({
  title,
  subTitle,
  children,
  customControls,
}: PropsWithChildren<{
  title: ReactNode;
  subTitle: ReactNode;
  customControls?: ReactNode;
}>) {
  const { onClose } = useDrawerContext();

  return (
    <header className={cn("px-6 py-4 border-b")}>
      <div
        className={cn("flex justify-between items-center gap-4", {
          "mb-4": !!children,
        })}
      >
        <div
          className={cn(
            "bg-brand-neutral-shade text-brand-blue-1",
            "w-auto h-auto p-2 rounded-full border",
            "flex items-center justify-center"
          )}
        >
          <RowsIcon className="w-6 h-6" />
        </div>

        <div className="grow">
          <h3 className="font-semibold text-brand-black text-lg">{title}</h3>
          <h4 className="text-brand-gray-4 text-sm">{subTitle}</h4>
        </div>

        {customControls}

        <Button
          analyticsEvent="station_details_close_button_clicked"
          className={cn(
            "bg-brand-white focus:outline-none",
            "text-brand-gray-4 hover:bg-brand-gray-1",
            "w-auto h-auto p-2 rounded-md",
            "flex items-center justify-center"
          )}
          onClick={onClose}
        >
          <XIcon className="w-6 h-6" />
        </Button>
      </div>

      {children}
    </header>
  );
}

export function OverviewDrawerContent({ children }: PropsWithChildren) {
  return (
    <section className="grow overflow-y-scroll px-6 py-6 min-h-full">
      {children}
    </section>
  );
}
