import { useLingui } from "@lingui/react";
import { ChevronDown } from "lucide-react";
import { Fragment, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { lineIdSchema } from "@/domain/line";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/view/components";
import { cn } from "@/view/utils";

import { useLevels } from "./levels-provider";
import { useSelectedLine } from "./selected-line-provider";

export function LineSelect({ path }: { path: `/line/:lineId${string}` }) {
  const { i18n } = useLingui();
  const navigate = useNavigate();
  const levels = useLevels();
  const line = useSelectedLine();
  const [open, setOpen] = useState(false);

  function handleChange(value: string) {
    const lineId = lineIdSchema.parse(value);
    navigate(generatePath(path, { lineId }));
  }

  const factory = levels.factoriesByFactoryId[line.factoryId];
  const label = `${line.shortName || line.name}, ${
    factory.shortName || factory.name
  }`;

  return (
    <DropdownMenu
      open={open}
      onOpenChange={(newOpen) => {
        if (newOpen) setOpen(newOpen);
      }}
    >
      <DropdownMenuTrigger asChild>
        <Button
          analyticsEvent="line_selector_button_clicked"
          className={cn(
            "bg-transparent hover:bg-brand-gray-2",
            "font-semibold text-brand-gray-5"
          )}
        >
          <span className="min-w-[160px] select-none flex justify-between items-center">
            {label ?? i18n.t("lineSelectorSelectLine")}
            <ChevronDown
              className={cn("ml-2 transition-transform transform", {
                "rotate-180": open,
              })}
            />
          </span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="start"
        className="min-w-[240px] rounded-lg"
        onInteractOutside={() => setOpen(false)}
      >
        <DropdownMenuRadioGroup value={line.id} onValueChange={handleChange}>
          {levels.levelsData.map((factory) => {
            return (
              <Fragment key={`f_${factory.id}`}>
                <DropdownMenuLabel className="text-brand-gray-4 text-xs py-1 mt-2">
                  {factory.shortName || factory.name}
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                {factory.lines.map((line) => {
                  return (
                    <DropdownMenuRadioItem
                      key={`f_${factory.id}-l_${line.id}`}
                      value={line.id}
                    >
                      <span className="flex gap-2 pr-8">
                        {`${line.shortName || line.name} `}
                        <span className="text-brand-gray-4 font-normal">
                          {i18n.t("lineSelectorStations", {
                            count: line.stations.length,
                          })}
                        </span>
                      </span>
                    </DropdownMenuRadioItem>
                  );
                })}
              </Fragment>
            );
          })}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
