import { generatePath, useNavigate } from "react-router-dom";

import { paths } from "@/view/routes";

import { useSelectedLine } from "../line-id/selected-line-provider";
import {
  encodeDateRangeToSearchParams,
  useSelectedDateRange,
} from "../line-id/use-selected-date-range";
import {
  encodeShiftIdsToSearchParams,
  useSelectedShiftIds,
} from "../line-id/use-selected-shift-ids";
import { AreaDetailsDrawer } from "./area-details-drawer";
import { AreaDetailsFiltersProvider } from "./area-details-filters-provider";
import { SelectedAreaProvider } from "./selected-area-provider";

export function LineBirdEyeViewAreaDetails() {
  const navigate = useNavigate();
  const line = useSelectedLine();
  const shiftIds = useSelectedShiftIds();
  const dateRange = useSelectedDateRange();

  function closeDrawer() {
    let searchParams = new URLSearchParams();
    searchParams = encodeShiftIdsToSearchParams(searchParams, shiftIds);
    searchParams = encodeDateRangeToSearchParams(searchParams, dateRange);
    navigate({
      pathname: generatePath(paths.lineBirdEyeViewPath, {
        lineId: line.id,
      }),
      search: searchParams.toString(),
    });
  }

  return (
    <SelectedAreaProvider>
      <AreaDetailsFiltersProvider>
        <AreaDetailsDrawer onClose={closeDrawer} />
      </AreaDetailsFiltersProvider>
    </SelectedAreaProvider>
  );
}
