import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { DateRangeFilter } from "@/domain/common/time-filter";
import { LineWithStations } from "@/domain/levels";
import { ShiftId } from "@/domain/shifts";
import { useAnalytics } from "@/view/providers/analytics-provider";
import { paths } from "@/view/routes";

import { DateRangeSelect } from "../line-id/date-range-select";
import {
  SelectedLineContext,
  useSelectedLine,
} from "../line-id/selected-line-provider";
import { ShiftSelect } from "../line-id/shift-select";
import {
  encodeDateRangeToSearchParams,
  parseDateRangeFromSearchParams,
  SelectedDateRangeContext,
} from "../line-id/use-selected-date-range";
import {
  encodeShiftIdsToSearchParams,
  parseShiftIdsFromSearchParams,
  SelectedShiftIdsContext,
} from "../line-id/use-selected-shift-ids";
import {
  BevType,
  encodeBevTypeToSearchParams,
  parseBevTypeFromSearchParams,
  SelectedBevTypeContext,
} from "./use-selected-bev-type";

type BirdEyeViewFilters = {
  line: LineWithStations;
  shiftIds: Array<ShiftId>;
  dateRange: DateRangeFilter;
  bevType: BevType;
};

type BirdEyeViewFilterActions = {
  selectShifts: (shift: Array<ShiftId>) => void;
  selectDateRange: (dateRange: DateRangeFilter) => void;
  selectBevType: (bevType: BevType) => void;
};

const BirdEyeViewFilterActionsContext =
  createContext<BirdEyeViewFilterActions | null>(null);

// eslint-disable-next-line react-refresh/only-export-components
export function useBirdEyeViewFilterActions() {
  const actions = useContext(BirdEyeViewFilterActionsContext);
  if (!actions) {
    throw new Error(
      "useBirdEyeViewFilterActions must be used inside BirdEyeViewFilterActionsProvider"
    );
  }
  return actions;
}

export function BirdEyeViewFiltersProvider({ children }: PropsWithChildren) {
  const line = useSelectedLine();
  const analytics = useAnalytics();
  const [state, setState] = useState<BirdEyeViewFilters>(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return {
      line,
      shiftIds: parseShiftIdsFromSearchParams(searchParams, line)!,
      dateRange: parseDateRangeFromSearchParams(searchParams),
      bevType: parseBevTypeFromSearchParams(searchParams),
    };
  });

  useEffect(() => {
    // synchronise with lineId from the URL
    if (state.line.id === line.id) return;
    setState((prev) => {
      const shiftIds = line.shifts.map((it) => it.id);
      return { ...prev, line, shiftIds };
    });
  }, [line, state.line.id]);

  // TODO: turn this into a generic "useSynchronizeUrl" custom hook
  // when the "useEffectEvent" hook is released in a stable version of React
  // => https://react.dev/learn/separating-events-from-effects#declaring-an-effect-event
  useEffect(() => {
    const locationLastSlice = window.location.pathname
      .split("/")
      .filter((it) => it)
      .at(-1);
    const reportingPath = paths.lineBirdEyeViewPath.split("/").at(-1);

    // only synchronise with URL when the location is the reporting page
    if (locationLastSlice !== reportingPath) return;

    let searchParams = new URLSearchParams();
    searchParams = encodeShiftIdsToSearchParams(searchParams, state.shiftIds);
    searchParams = encodeDateRangeToSearchParams(searchParams, state.dateRange);
    searchParams = encodeBevTypeToSearchParams(searchParams, state.bevType);

    // synchronise with URL
    window.history.replaceState(
      null,
      "",
      `${window.location.pathname}?${searchParams}`
    );

    // send analytics event
    analytics.collect("filters_changed");
  }, [state, analytics]);

  const selectedLine = useMemo(() => state.line, [state.line]);
  const selectedShiftIds = useMemo(() => state.shiftIds, [state.shiftIds]);
  const selectedDateRange = useMemo(() => state.dateRange, [state.dateRange]);
  const bevType = useMemo(() => state.bevType, [state.bevType]);
  const actions = useMemo<BirdEyeViewFilterActions>(() => {
    return {
      selectShifts(shiftIds) {
        setState((prev) => ({ ...prev, shiftIds }));
      },
      selectDateRange(dateRange) {
        setState((prev) => ({ ...prev, dateRange }));
      },
      selectBevType(bevType) {
        setState((prev) => ({ ...prev, bevType }));
      },
    };
  }, []);

  return (
    // provide the line context again, so that the "selectedLine" comes
    // from state value, which prevents double rerenders caused by line change
    <SelectedLineContext.Provider value={selectedLine}>
      <SelectedShiftIdsContext.Provider value={selectedShiftIds}>
        <SelectedDateRangeContext.Provider value={selectedDateRange}>
          <SelectedBevTypeContext.Provider value={bevType}>
            <BirdEyeViewFilterActionsContext.Provider value={actions}>
              {children}
            </BirdEyeViewFilterActionsContext.Provider>
          </SelectedBevTypeContext.Provider>
        </SelectedDateRangeContext.Provider>
      </SelectedShiftIdsContext.Provider>
    </SelectedLineContext.Provider>
  );
}

export function BirdEyeViewFilters() {
  const actions = useBirdEyeViewFilterActions();
  return (
    <>
      <ShiftSelect onChange={actions.selectShifts} />
      <DateRangeSelect onChange={actions.selectDateRange} />
    </>
  );
}
