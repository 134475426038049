import { useLingui } from "@lingui/react";
import { ListFilterIcon, TagIcon, WandSparklesIcon } from "lucide-react";
import { useMemo, useState } from "react";

import { StationId } from "@/domain/station";
import { getAllowedTagIds, getValidTagIds, Tag, TagId } from "@/domain/tag";
import {
  Button,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/view/components";
import { cn } from "@/view/utils";

import { StationsFilters } from "./stations-select";
import { useSelectedTagIds } from "./use-selected-tag-ids";

export function FiltersSelect({
  availableTags,
  onStationIdsChange,
  onTagIdsChange,
}: {
  availableTags: Array<Tag>;
  onStationIdsChange: (ids: Array<StationId>) => void;
  onTagIdsChange: (ids: Array<TagId>) => void;
}) {
  const [open, setOpen] = useState(false);
  const initialTagIds = useSelectedTagIds();
  const [tagIds, setTagIds] = useState(initialTagIds);

  function closeMenu() {
    setOpen(false);
    onTagIdsChange(tagIds);
  }

  return (
    <DropdownMenu open={open}>
      <DropdownMenuTrigger asChild>
        <Button
          onClick={() => setOpen(true)}
          analyticsEvent="tag_station_filter_clicked"
          className={cn(
            "border-2 rounded-lg border-brand-gray-2",
            "bg-brand-white hover:bg-brand-gray-2",
            "font-semibold text-brand-gray-5"
          )}
        >
          <FiltersSelectLabel />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className="min-w-[240px] p-0 rounded-lg flex"
        onEscapeKeyDown={closeMenu}
        onInteractOutside={closeMenu}
      >
        <div className="border-r">
          <StationsFilters onChange={onStationIdsChange} />
        </div>
        <div>
          <TagsFilters
            tags={availableTags}
            selectedIds={tagIds}
            onChange={setTagIds}
          />
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function FiltersSelectLabel() {
  const { i18n } = useLingui();
  return (
    <span className="select-none flex gap-2 justify-between items-center">
      <ListFilterIcon />
      {i18n.t("Filters")}
    </span>
  );
}

function TagsFilters({
  tags,
  selectedIds,
  onChange,
}: {
  tags: Array<Tag>;
  selectedIds: Array<TagId>;
  onChange: (selectedIds: Array<TagId>) => void;
}) {
  const { i18n } = useLingui();
  const availableTagIds = useMemo(() => getAllowedTagIds(tags), [tags]);
  return (
    <>
      <DropdownMenuLabel className="py-3 px-4 flex gap-2 items-center">
        <TagIcon className="w-4 h-4 text-brand-blue-1" />
        <h3 className="text-sm">{i18n.t("Tags")}</h3>
      </DropdownMenuLabel>
      <DropdownMenuSeparator />
      <div className="max-h-[290px] overflow-y-auto">
        {tags.map((tag) => {
          return (
            <DropdownMenuCheckboxItem
              key={tag.id}
              checked={selectedIds.includes(tag.id)}
              className="mx-1"
              onClick={() => {
                onChange(
                  selectedIds.includes(tag.id)
                    ? selectedIds.filter((id) => id !== tag.id)
                    : getValidTagIds([...selectedIds, tag.id], availableTagIds)
                );
              }}
            >
              <span className="flex items-center gap-2 border rounded-sm px-1 py-0.5">
                {tag.type === "System" ? (
                  <WandSparklesIcon
                    className="w-4"
                    style={{ color: tag.color }}
                  />
                ) : (
                  <TagIcon className="w-4" style={{ color: tag.color }} />
                )}
                <span className="grow">{tag.name}</span>
              </span>
            </DropdownMenuCheckboxItem>
          );
        })}
      </div>
      <DropdownMenuSeparator />
      <DropdownMenuItem
        className={cn(
          "m-1 py-2.5 px-2",
          "text-brand-blue-1",
          "hover:bg-brand-gray-2"
        )}
        onClick={() => onChange([])}
      >
        <span className="w-full text-center">{i18n.t("Remove selection")}</span>
      </DropdownMenuItem>
    </>
  );
}
