import { useLingui } from "@lingui/react";

import bgPatternImg from "@/view/assets/bg-pattern.png";
import searchCloudImg from "@/view/assets/search-cloud.png";
import { cn } from "@/view/utils";

type Size = "small" | "medium";

export function ChartErrorStatus({
  title,
  description,
  size = "medium",
}: {
  title?: string;
  description?: string;
  size?: Size;
}) {
  const { i18n } = useLingui();
  return (
    <div
      className="grow flex items-center justify-center gap-12 bg-no-repeat bg-center"
      style={{ backgroundImage: `url(${bgPatternImg})` }}
    >
      <div className="text-center max-w-md">
        <img
          src={searchCloudImg}
          alt=""
          className={cn("mx-auto", {
            "w-24 mb-2": size === "small",
            "w-36 mb-8": size === "medium",
          })}
        />
        <p
          className={cn("font-bold text-brand-black", {
            "text-base": size === "small",
            "text-xl": size === "medium",
          })}
        >
          {title ?? i18n.t("lineOverviewChartErrorTitle")}
        </p>
        <p
          className={cn({
            "text-sm": size === "small",
            "text-base": size === "medium",
          })}
        >
          {description ?? i18n.t("lineOverviewChartErrorDescription")}
        </p>
      </div>
    </div>
  );
}
