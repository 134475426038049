import { generatePath, useNavigate } from "react-router-dom";

import { StationId } from "@/domain/station";
import { paths } from "@/view/routes";

import { useSelectedLine } from "../line-id/selected-line-provider";
import {
  encodeDateRangeToSearchParams,
  useSelectedDateRange,
} from "../line-id/use-selected-date-range";
import {
  encodeShiftIdsToSearchParams,
  useSelectedShiftIds,
} from "../line-id/use-selected-shift-ids";
import {
  encodeStationIdsToSearchParams,
  useSelectedStationIds,
} from "../line-id/use-selected-station-ids";
import {
  encodeChartTypeToSearchParams,
  useSelectedChartType,
} from "../line-id-reporting/use-selected-line-chart";
import { SelectedStationDetailsProvider } from "./selected-station-provider";
import { StationDetailsDrawer } from "./station-details-drawer";
import { StationReportingFiltersProvider } from "./station-reporting-filters-provider";

export function StationDetailsPage() {
  const navigate = useNavigate();
  const line = useSelectedLine();
  const shiftIds = useSelectedShiftIds();
  const dateRange = useSelectedDateRange();
  const stationsIds = useSelectedStationIds();
  const chartType = useSelectedChartType();

  function onStationSelect(stationId: StationId | null) {
    if (stationId) {
      const path = generatePath(paths.lineReportingStationDetailsPath, {
        lineId: line.id,
        stationId,
      });
      navigate(path);
    } else {
      let searchParams = new URLSearchParams();
      searchParams = encodeShiftIdsToSearchParams(searchParams, shiftIds);
      searchParams = encodeDateRangeToSearchParams(searchParams, dateRange);
      searchParams = encodeStationIdsToSearchParams(searchParams, stationsIds);
      searchParams = encodeChartTypeToSearchParams(searchParams, chartType);
      navigate(
        generatePath(paths.lineReportingPath, {
          lineId: line.id,
        }) + `?${searchParams}`
      );
    }
  }

  return (
    <SelectedStationDetailsProvider>
      <StationReportingFiltersProvider>
        <StationDetailsDrawer onStationSelect={onStationSelect} />
      </StationReportingFiltersProvider>
    </SelectedStationDetailsProvider>
  );
}
