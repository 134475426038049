import { useLingui } from "@lingui/react";
import * as BaseSlider from "@radix-ui/react-slider";
import { Zoom } from "@visx/zoom";
import { TransformMatrix } from "@visx/zoom/lib/types";
import { curveBumpY as curveFunc, line as d3line } from "d3-shape";
import { MouseEventHandler, useEffect, useMemo, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import type {
  AreaColor,
  AreaCoordinates,
  AreaId,
  Dimensions,
  VirtualSensor,
} from "@/domain/areas-of-interests";
import { Card, CardHeader, CardTitle } from "@/view/components";
import { useSelectedLine } from "@/view/pages/line-id/selected-line-provider";
import { WalkingRoutesHeatmap } from "@/view/pages/line-id-bev/heatmap";
import { useDebouncedValue } from "@/view/providers/use-debounced-value";
import { useElementSize } from "@/view/providers/use-element-size";
import { paths } from "@/view/routes";
import { cn } from "@/view/utils";

import { useBirdEyeViewFilterActions } from "./bev-filters-provider";
import { BevType, useSelectedBevType } from "./use-selected-bev-type";
import { useVirtualSensor } from "./use-virtual-sensor";

const line = d3line<{ x: number; y: number }>()
  .x((d) => d.x)
  .y((d) => d.y)
  .curve(curveFunc);

export function BirdEyeViewCanvas() {
  const sensor = useVirtualSensor();
  const { ref, width, height } = useElementSize();
  return (
    <Card
      ref={(el) => (ref.current = el)}
      className="relative border shadow-sm aspect-video"
    >
      <div className="absolute inset-0">
        {width > 0 && height > 0 && (
          <BirdEyeView
            key={sensor.bgUrl}
            sensor={sensor}
            canvas={{
              width: Math.round(width),
              height: Math.round(height) - 96, // subtract the height of the controls
            }}
          />
        )}
      </div>
    </Card>
  );
}

function BirdEyeView({
  canvas,
  sensor,
}: {
  canvas: Dimensions;
  sensor: VirtualSensor;
}) {
  const navigate = useNavigate();
  const line = useSelectedLine();
  const viewType = useSelectedBevType();
  const [selectedAreaId, setSelectedAreaId] = useState<AreaId | null>(null);
  const [opacityPercentages, setOpacityPercentages] = useState(75);
  const opacity = useDebouncedValue(opacityPercentages / 100, 200);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const selectedArea = useMemo(
    () => sensor.areas.find((area) => area.id === selectedAreaId),
    [sensor, selectedAreaId]
  );

  useEffect(() => {
    const abortController = new AbortController();

    containerRef.current?.addEventListener(
      "wheel",
      (e) => {
        e.preventDefault();
      },
      {
        passive: false,
        signal: abortController.signal,
      }
    );

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <Zoom
      width={canvas.width}
      height={canvas.height}
      initialTransformMatrix={getInitialTransformMatrix(canvas, sensor)}
      scaleXMin={1 / 4}
      scaleXMax={2}
      scaleYMin={1 / 4}
      scaleYMax={2}
    >
      {(zoom) => (
        <>
          <CanvasControls
            opacity={opacityPercentages}
            viewType={viewType}
            onChangeOpacity={setOpacityPercentages}
            onResetMatrix={zoom.reset}
          />
          <div className="relative" ref={containerRef}>
            <svg
              // @ts-expect-error typescript complains about the ref type,
              // but in order to to prevent passive event errors on wheel event
              // we need to use containerRef from Zoom component
              ref={zoom.containerRef}
              width={canvas.width}
              height={canvas.height}
              style={{
                cursor: zoom.isDragging ? "grabbing" : "grab",
                display: "block",
                userSelect: "none",
                backgroundColor: "#F5F7FA",
                touchAction: "none",
              }}
            >
              <defs>
                <pattern
                  id="grid"
                  width={72}
                  height={72}
                  patternUnits="userSpaceOnUse"
                >
                  <circle cx={36} cy={36} r={2} fill="rgba(0,0,0,0.1)" />
                </pattern>
              </defs>
              <rect
                fill="url(#grid)"
                width={canvas.width}
                height={canvas.height}
              />
              <g transform={zoom.toString()}>
                <image
                  href={sensor.bgUrl}
                  width={sensor.dimensions.width}
                  height={sensor.dimensions.height}
                />
                {viewType === "walking_routes" && (
                  <>
                    {sensor.areas.map((area) => {
                      return (
                        <g
                          key={area.id}
                          transform={`translate(${area.coordinates.x}, ${area.coordinates.y})`}
                        >
                          <CanvasArea
                            key={area.id}
                            opacity={opacity}
                            name={area.name}
                            color={area.color}
                            width={area.coordinates.width}
                            height={area.coordinates.height}
                            isSelected={selectedAreaId == area.id}
                            onClick={(e) => {
                              if (e.detail === 1) {
                                setSelectedAreaId(area.id);
                              }
                              if (e.detail === 2) {
                                if (area.stationId) {
                                  const path = generatePath(
                                    paths.lineBirdEyeViewStationDetailsOverviewPath,
                                    {
                                      lineId: line.id,
                                      areaId: area.id,
                                      stationId: area.stationId,
                                    }
                                  );
                                  navigate(path);
                                } else {
                                  const path = generatePath(
                                    paths.lineBirdEyeViewAreaDetailsPath,
                                    {
                                      lineId: line.id,
                                      areaId: area.id,
                                    }
                                  );
                                  navigate(path);
                                }
                              }
                            }}
                          />
                        </g>
                      );
                    })}
                    {selectedArea && (
                      <>
                        {selectedArea.connections.map((connection) => {
                          const toAreaCoordinates =
                            sensor.areaCoordinatesByAreaId[connection.areaId];
                          // make sure we have coordinates for the connected area
                          if (!toAreaCoordinates) return null;
                          return (
                            <CanvasAreaConnection
                              key={`${selectedArea.id}_${connection.areaId}_connections`}
                              fromCoordinates={selectedArea.coordinates}
                              toCoordinates={toAreaCoordinates}
                            />
                          );
                        })}
                        {selectedArea.connections.map((connection) => {
                          const toAreaCoordinates =
                            sensor.areaCoordinatesByAreaId[connection.areaId];
                          // make sure we have coordinates for the connected area
                          if (!toAreaCoordinates) return null;
                          return (
                            <CanvasAreaConnectionLabel
                              key={`${selectedArea.id}_${connection.areaId}_labels`}
                              label={`${connection.count}x`}
                              toCoordinates={toAreaCoordinates}
                            />
                          );
                        })}
                      </>
                    )}
                  </>
                )}
                {viewType === "heatmap" && (
                  <WalkingRoutesHeatmap
                    width={sensor.dimensions.width}
                    height={sensor.dimensions.height}
                    cameraId={sensor.id}
                    opacity={opacity}
                  />
                )}
              </g>
            </svg>
          </div>
        </>
      )}
    </Zoom>
  );
}

function CanvasAreaConnectionLabel({
  label,
  toCoordinates,
}: {
  label: string;
  toCoordinates: AreaCoordinates;
}) {
  const height = 32;
  const width = 80;
  const x = toCoordinates.x + toCoordinates.width / 2 - width / 2;
  const y = toCoordinates.y + toCoordinates.height / 2 - height / 2;

  return (
    <g pointerEvents="none">
      <rect
        x={x}
        y={y}
        height={height}
        width={width}
        rx={height / 2}
        fill="rgba(20, 142, 255, 0.9)"
        stroke="rgba(20, 142, 255, 1)"
        strokeWidth={1}
      />
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill="white"
        fontWeight="bold"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {label}
      </text>
    </g>
  );
}

function CanvasAreaConnection({
  fromCoordinates,
  toCoordinates,
}: {
  fromCoordinates: AreaCoordinates;
  toCoordinates: AreaCoordinates;
}) {
  const points = getConnectionPoints(fromCoordinates, toCoordinates);
  const path = line(points) ?? "";

  return (
    <g pointerEvents="none">
      <defs>
        <marker
          id="arrow"
          markerWidth="10"
          markerHeight="10"
          refX="5"
          refY="5"
          orient="auto"
        >
          <path d="M0,0 L10,5 L0,10 Z" fill="rgba(20, 142, 255, 0.9)" />
        </marker>
      </defs>
      <path
        d={path}
        fill="none"
        stroke="rgba(20, 142, 255, 0.9)"
        strokeWidth={2}
        markerMid="url(#arrow)"
      />
    </g>
  );
}

function CanvasControls({
  opacity,
  viewType,
  onChangeOpacity,
  onResetMatrix,
}: {
  opacity: number;
  viewType: BevType;
  onChangeOpacity: (value: number) => void;
  onResetMatrix: () => void;
}) {
  const actions = useBirdEyeViewFilterActions();
  const { i18n } = useLingui();
  const toggleGroupItemClasses = [
    "flex py-3 px-4 items-center justify-center",
    "focus:z-10 focus:outline-none",
    "first:rounded-l last:rounded-r",
    "text-brand-gray-5 text-base leading-4",
    "bg-brand-white hover:bg-brand-gray-1",
  ];
  return (
    <CardHeader className="flex-row gap-6 justify-between items-center">
      <CardTitle>{i18n.t("Bird eye view")}</CardTitle>
      <div className="grow">
        <div className="inline-flex border border-brand-gray-2 bg-brand-gray-2 rounded space-x-px">
          <button
            type="button"
            disabled={viewType === "walking_routes"}
            onClick={() => actions.selectBevType("walking_routes")}
            className={cn(toggleGroupItemClasses, {
              "bg-brand-gray-2 hover:bg-brand-gray-2":
                viewType === "walking_routes",
            })}
          >
            {i18n.t("Walking routes")}
          </button>
          <button
            type="button"
            disabled={viewType === "heatmap"}
            onClick={() => actions.selectBevType("heatmap")}
            className={cn(toggleGroupItemClasses, {
              "bg-brand-gray-2 hover:bg-brand-gray-2": viewType === "heatmap",
            })}
          >
            {i18n.t("Heatmap")}
          </button>
        </div>
      </div>
      <div className="inline-flex gap-2 items-center">
        <h4 className="font-semibold text-brand-gray-5">
          {i18n.t("Overlay Opacity")}
        </h4>
        <Slider value={opacity} onChange={onChangeOpacity} />
      </div>
      <div>
        <div className="inline-flex border border-brand-gray-2 bg-brand-gray-2 rounded space-x-px">
          <button
            type="button"
            className={toggleGroupItemClasses.join(" ")}
            onClick={onResetMatrix}
          >
            {i18n.t("Reset view")}
          </button>
        </div>
      </div>
    </CardHeader>
  );
}

function CanvasArea({
  opacity,
  name,
  color,
  width,
  height,
  isSelected,
  onClick,
}: {
  opacity: number;
  name: string;
  color: AreaColor;
  isSelected: boolean;
  onClick?: MouseEventHandler<SVGRectElement>;
} & Dimensions) {
  return (
    <g>
      <rect
        fill={`rgba(${color.join(",")}, ${isSelected ? 0.95 : opacity})`}
        stroke={isSelected ? "white" : `rgba(${color.join(",")}, 0.85)`}
        strokeWidth={1}
        rx={6}
        height={height}
        width={width}
        style={{ cursor: "pointer" }}
        onClick={onClick}
      />
      <text
        x={8}
        y={16}
        dominantBaseline="middle"
        fill="white"
        fontSize={16}
        fontWeight="bold"
      >
        {name}
      </text>
    </g>
  );
}

function Slider({
  value,
  onChange,
}: {
  value: number;
  onChange: (value: number) => void;
}) {
  return (
    <form>
      <BaseSlider.Root
        className="relative flex items-center select-none touch-none w-[200px] h-5"
        defaultValue={[75]}
        max={90}
        min={30}
        step={1}
        value={[value]}
        onValueChange={(value) => onChange(value[0])}
      >
        <BaseSlider.Track className="bg-brand-gray-3 relative grow rounded-full h-[8px]">
          <BaseSlider.Range className="absolute bg-brand-blue-1 rounded-full h-full" />
        </BaseSlider.Track>
        <BaseSlider.Thumb
          className="block w-5 h-5 bg-white border-brand-blue-1 border-2 rounded-[10px] hover:bg-brand-gray-2 focus:outline-none"
          aria-label="Volume"
        />
      </BaseSlider.Root>
    </form>
  );
}

function getInitialTransformMatrix(
  canvas: Dimensions,
  sensor: VirtualSensor
): TransformMatrix {
  const canvasRatio = canvas.width / canvas.height;
  const sensorRatio = sensor.dimensions.width / sensor.dimensions.height;
  const isHorizontal = sensorRatio > canvasRatio;
  const heightBased = {
    width: Math.round(canvas.height * sensorRatio),
    height: canvas.height,
    scale: canvas.height / sensor.dimensions.height,
  };
  const widthBased = {
    width: canvas.width,
    height: Math.round(canvas.width / sensorRatio),
    scale: canvas.width / sensor.dimensions.width,
  };
  const { scale, width, height } = isHorizontal ? widthBased : heightBased;
  return {
    scaleX: scale,
    scaleY: scale,
    translateX: (canvas.width - width) / 2,
    translateY: (canvas.height - height) / 2,
    skewX: 0,
    skewY: 0,
  };
}

function getAreaSideCenters(area: AreaCoordinates) {
  return [
    { x: area.x, y: area.y + area.height / 2 }, // left center
    { x: area.x + area.width, y: area.y + area.height / 2 }, // right center
    { x: area.x + area.width / 2, y: area.y }, // top center
    { x: area.x + area.width / 2, y: area.y + area.height }, // bottom center
  ];
}

function getConnectionPoints(
  startArea: AreaCoordinates,
  endArea: AreaCoordinates
) {
  const startPoints = getAreaSideCenters(startArea);
  const endPoints = getAreaSideCenters(endArea);

  let minDistance = Infinity;
  let startPoint = startPoints[0];

  for (const sp of startPoints) {
    for (const ep of endPoints) {
      const distance = Math.hypot(ep.x - sp.x, ep.y - sp.y);
      if (distance < minDistance) {
        minDistance = distance;
        startPoint = sp;
      }
    }
  }

  const endPoint = {
    x: endArea.x + endArea.width / 2,
    y: endArea.y + endArea.height / 2,
  };
  const midPoint = {
    x: (startPoint.x + endPoint.x) / 2,
    y: (startPoint.y + endPoint.y) / 2,
  };

  return [startPoint, midPoint, endPoint];
}
