import { useSuspenseQuery } from "@tanstack/react-query";
import { createContext, useContext } from "react";

import { VirtualSensor } from "@/domain/areas-of-interests";
import { DateRangeFilter } from "@/domain/common/time-filter";
import { LineId } from "@/domain/line";
import { getSelectedShifts, ShiftId } from "@/domain/shifts";
import { useApiClient } from "@/view/providers/api-client-provider";

import { useSelectedLine } from "../line-id/selected-line-provider";
import { useSelectedDateRange } from "../line-id/use-selected-date-range";
import { useSelectedShiftIds } from "../line-id/use-selected-shift-ids";

export const VirtualSensorContext = createContext<VirtualSensor | null>(null);

export function useVirtualSensor() {
  const lineStatistics = useContext(VirtualSensorContext);
  if (!lineStatistics) {
    throw new Error(
      "useLineStatistics must be used within a LineStatisticsProvider"
    );
  }
  return lineStatistics;
}

function getVirtualSensorKey(
  lineId: LineId,
  dateRange: DateRangeFilter,
  shiftIds: Array<ShiftId>
) {
  return ["virtual-sensor", lineId, dateRange, shiftIds] as const;
}

export function useVirtualSensorParams(): [
  ReturnType<typeof useSelectedLine>,
  ReturnType<typeof useSelectedShiftIds>,
  ReturnType<typeof useSelectedDateRange>,
] {
  const line = useSelectedLine();
  const shiftIds = useSelectedShiftIds();
  const dateRange = useSelectedDateRange();
  return [line, shiftIds, dateRange];
}

export function useVirtualSensorQuery() {
  const apiClient = useApiClient();
  const [line, shiftIds, dateRange] = useVirtualSensorParams();
  return useSuspenseQuery({
    queryKey: getVirtualSensorKey(line.id, dateRange, shiftIds),
    queryFn: ({ signal }) => {
      const shifts = getSelectedShifts(line.shifts, shiftIds);
      return apiClient.getVirtualSensor(
        { lineId: line.id, dateRange, shifts },
        { signal }
      );
    },
    staleTime: Infinity,
    refetchInterval: 1000 * 60 * 30, // Half an hour
    refetchIntervalInBackground: true,
  });
}
