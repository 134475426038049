import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useSearchParams } from "react-router-dom";

import { DateRangeFilter } from "@/domain/common/time-filter";
import { LineWithStations } from "@/domain/levels";
import { ShiftId } from "@/domain/shifts";
import { ShiftSelect } from "@/view/pages/line-id/shift-select";
import { useAnalytics } from "@/view/providers/analytics-provider";

import { DateRangeSelect } from "../line-id/date-range-select";
import { useSelectedLine } from "../line-id/selected-line-provider";
import {
  encodeDateRange,
  parseDateRangeFromSearchParams,
  SelectedDateRangeContext,
} from "../line-id/use-selected-date-range";
import {
  encodeShiftIds,
  parseShiftIdsFromSearchParams,
  SelectedShiftIdsContext,
} from "../line-id/use-selected-shift-ids";

type AreaDetailsFilters = {
  shiftIds: Array<ShiftId>;
  dateRange: DateRangeFilter;
};

type AreaDetailsFilterActions = {
  selectShifts: (shiftIds: Array<ShiftId>) => void;
  selectDateRange: (dateRange: DateRangeFilter) => void;
};

const AreaDetailsFilterActionsContext =
  createContext<AreaDetailsFilterActions | null>(null);

function useAreaDetailsFilterActions() {
  const actions = useContext(AreaDetailsFilterActionsContext);
  if (!actions) {
    throw new Error(
      "useAreaDetailsFilterActions must be used inside AreaDetailsFilterActionsProvider"
    );
  }
  return actions;
}

const getDefaultSearchParams = (line: LineWithStations) => {
  const searchParams = new URLSearchParams(window.location.search);

  searchParams.set(
    "dateRange",
    encodeDateRange(parseDateRangeFromSearchParams(searchParams))
  );
  searchParams.set(
    "shiftIds",
    encodeShiftIds(parseShiftIdsFromSearchParams(searchParams, line)!)
  );

  return searchParams;
};

export function AreaDetailsFiltersProvider({ children }: PropsWithChildren) {
  const analytics = useAnalytics();
  const line = useSelectedLine();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams(getDefaultSearchParams(line), { replace: true });
  }, [line, setSearchParams]);

  const selectedShiftIds = useMemo(
    () => parseShiftIdsFromSearchParams(searchParams, line),
    [line, searchParams]
  );
  const selectedDateRange = useMemo(
    () => parseDateRangeFromSearchParams(searchParams),
    [searchParams]
  );

  const actions = useMemo<AreaDetailsFilterActions>(() => {
    return {
      selectShifts(shiftIds) {
        setSearchParams((prev) => {
          prev.set("shiftIds", encodeShiftIds(shiftIds));

          return prev;
        });

        analytics.collect("filters_changed");
      },
      selectDateRange(dateRange) {
        setSearchParams((prev) => {
          prev.set("dateRange", encodeDateRange(dateRange));

          return prev;
        });

        analytics.collect("filters_changed");
      },
    };
  }, [analytics, setSearchParams]);

  return (
    <SelectedShiftIdsContext.Provider value={selectedShiftIds}>
      <SelectedDateRangeContext.Provider value={selectedDateRange}>
        <AreaDetailsFilterActionsContext.Provider value={actions}>
          {children}
        </AreaDetailsFilterActionsContext.Provider>
      </SelectedDateRangeContext.Provider>
    </SelectedShiftIdsContext.Provider>
  );
}

export function AreaDetailsFilters() {
  const actions = useAreaDetailsFilterActions();
  return (
    <>
      <ShiftSelect onChange={actions.selectShifts} />
      <DateRangeSelect onChange={actions.selectDateRange} />
    </>
  );
}
