import { useLingui } from "@lingui/react";
import { BarDatum, BarItem, BarItemProps, ResponsiveBar } from "@nivo/bar";
import { useState } from "react";

import { StationId } from "@/domain/station";
import { CycleCountByStation } from "@/domain/statistics";

import { useSelectedTagIds } from "../../line-id/use-selected-tag-ids";
import { useTags } from "../../line-id/use-tags";
import { ChartLegend } from "./chart-legend";
import { ChartType, ChartTypeSelector } from "./chart-type-select";
import { ChartWrapper } from "./chart-wrapper";
import {
  getColor,
  getKeysForChartType,
  getLegendName,
  getValueForChartType,
} from "./helpers";
import { useStationName } from "./use-station-name";

export function CycleCountByStationChart({
  data,
  height,
  onBarClick,
}: {
  data: Array<CycleCountByStation>;
  height: number;
  onBarClick?: (label: StationId) => void;
}) {
  const { i18n } = useLingui();
  const tagsStore = useTags();
  const selectedTagIds = useSelectedTagIds();
  const getStationName = useStationName();
  const [hiddenLegends, setHiddenLegends] = useState<Array<string>>([]);
  const [chartType, setChartType] = useState<ChartType>("single");
  const keys = getKeysForChartType("cycleCount", chartType, selectedTagIds);

  const chartData = data.map((it) =>
    getValueForChartType(chartType, it, hiddenLegends)
  );

  const maxValue = Math.max(
    10,
    ...data.flatMap((it) => {
      return it[chartType]
        .filter((it) => !hiddenLegends.includes(it.key))
        .map((it) => it.value);
    })
  );

  return (
    <ChartWrapper
      height={height}
      controls={
        <>
          <ChartLegend
            dataType="cycleCount"
            chartType={chartType}
            legends={keys}
            hiddenLegends={hiddenLegends}
            onLegendClick={(legend) =>
              setHiddenLegends((it) => {
                if (it.includes(legend)) {
                  return it.filter((l) => l !== legend);
                } else {
                  return [...it, legend];
                }
              })
            }
          />
          <ChartTypeSelector
            options={["single", "combined", "compared"]}
            value={chartType}
            onChange={(type) => {
              setChartType(type);
              setHiddenLegends([]);
            }}
          />
        </>
      }
    >
      <ResponsiveBar
        indexBy="stationId"
        data={chartData}
        keys={keys}
        groupMode={chartType == "compared" ? "grouped" : "stacked"}
        onClick={(d) => onBarClick?.(d.data.stationId)}
        // styling
        barComponent={CustomBarItem}
        margin={{ top: 60, right: 0, bottom: 120, left: 150 }}
        colors={(d) => getColor(`${d.id}`, chartType, "cycleCount", tagsStore)}
        maxValue={maxValue}
        padding={chartType !== "compared" ? 0.6 : 0.1}
        labelSkipHeight={16}
        labelTextColor={{ from: "color", modifiers: [["darker", 2]] }}
        borderRadius={3}
        enableTotals={chartType !== "compared"}
        enableLabel={chartType !== "single"}
        tooltipLabel={(d) => {
          const stationName = getStationName(d.data.stationId);
          const legendName = getLegendName(
            `${d.id}`,
            chartType,
            "cycleCount",
            tagsStore,
            i18n
          );
          return `${stationName} - ${legendName}`;
        }}
        axisLeft={{
          legend: i18n.t("lineOverviewChartTabOutput"),
          legendPosition: "middle",
          legendOffset: -90,
        }}
        axisBottom={{
          tickPadding: 12,
          tickRotation: -14,
          truncateTickAt: 10,
          format: (value) => getStationName(value as StationId),
        }}
        theme={{
          grid: {
            line: {
              stroke: "#e5eaf0",
            },
          },
        }}
      />
    </ChartWrapper>
  );
}

function CustomBarItem<RawDatum extends BarDatum>(
  props: BarItemProps<RawDatum>
) {
  return (
    <g className="cursor-pointer">
      <BarItem {...props} />
    </g>
  );
}
