import { useLingui } from "@lingui/react";
import { animated, to, useSpring, useTransition } from "@react-spring/web";
import { ScaleBand, scaleBand, ScaleLinear, scaleLinear } from "d3-scale";
import { curveMonotoneX as d3curve, line as d3line } from "d3-shape";
import React, {
  PropsWithChildren,
  SVGAttributes,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Group } from "@/view/components";
import { Label } from "@/view/components/label";
import { Switch } from "@/view/components/switch";
import { cn, formatCycleTimeDifference } from "@/view/utils";

type DataPoint<T> = {
  label: T;
  value: Record<string, number>;
};

type Dimensions = {
  width: number;
  height: number;
};

const axisLabelFontSize = 14;
const axisTickSize = 12;
const axisTextOffset = axisTickSize + 4;
const marginLeft = axisTickSize * 12; // width space for y-axis legend
const marginTop = axisTickSize * 2; // some top padding
const marginBottom = axisTickSize * 8;
const maxBarWidth = 32;
const minBarWidth = 28;

function TruncatedText({
  children,
  maxWidth,
  ellipsis = "..",
  className,
  ...props
}: SVGAttributes<SVGTextElement> & {
  children: string;
  maxWidth: number;
  ellipsis?: string;
}) {
  const ref = useRef<SVGTextElement>(null);

  useLayoutEffect(() => {
    if (!ref.current) return;

    const node = ref.current;
    node.textContent = children;
    let textLength = node.getComputedTextLength();
    let textContent = node.textContent ?? "";

    while (textLength > maxWidth && textContent.length > 0) {
      textContent = textContent.slice(0, -2).trimEnd();
      node.textContent = textContent + ellipsis;
      textLength = node.getComputedTextLength();
    }
  }, [children, maxWidth, ellipsis]);

  return (
    <text
      ref={ref}
      className={cn("text-brand-gray-4", className)}
      fontSize={axisLabelFontSize}
      alignmentBaseline="text-before-edge"
      textAnchor="middle"
      stroke="white"
      strokeWidth="4"
      paintOrder="stroke"
      {...props}
    >
      {children}
    </text>
  );
}

export function BasicLineChart({
  xAxisTitle,
  xLabelFormatter = (value) => value.toISOString(),
  yAxisTitle,
  yValueFormatter = (value) => `${value}`,
  data,
  dimensions,
}: {
  xAxisTitle: string;
  yAxisTitle: string;
  xLabelFormatter?: (value: Date) => string;
  yValueFormatter?: (value: number) => string;
  data: Array<DataPoint<Date>>;
  dimensions: Dimensions;
}) {
  const { chartWidth, chartHeight, yAxisScale } = useChartYAxis(
    dimensions,
    data,
    axisTickSize * 4
  );

  const xAxisScale = scaleBand<Date>()
    .domain(data.map((d) => d.label))
    .rangeRound([0, chartWidth]);

  return (
    <BasicChartWrapper
      xAxisTitle={xAxisTitle}
      yAxisTitle={yAxisTitle}
      yValueFormatter={yValueFormatter}
      yAxisScale={yAxisScale}
      chartHeight={chartHeight}
      chartWidth={chartWidth}
      containerWidth={dimensions.width}
      containerHeight={dimensions.height}
    >
      <Group className="text-brand-blue-1" left={xAxisScale.bandwidth() / 2}>
        <DataLine
          dataKey="value"
          xAxisScale={xAxisScale}
          yAxisScale={yAxisScale}
          data={data}
          chartHeight={chartHeight}
          yValueFormatter={yValueFormatter}
        />
      </Group>
      <Group
        className="text-brand-blue-1"
        top={chartHeight}
        left={xAxisScale.bandwidth() / 2}
      >
        <AxisBottom xAxisScale={xAxisScale} xLabelFormatter={xLabelFormatter} />
      </Group>
    </BasicChartWrapper>
  );
}

export function OutputByTimeChart({
  xAxisTitle,
  xLabelFormatter = (value) => value.toISOString(),
  yAxisTitle,
  yValueFormatter = (value) => `${value}`,
  data,
  dimensions,
}: {
  xAxisTitle: string;
  yAxisTitle: string;
  xLabelFormatter?: (value: Date) => string;
  yValueFormatter?: (value: number) => string;
  data: Array<DataPoint<Date>>;
  dimensions: Dimensions;
}) {
  const { chartWidth, chartHeight, yAxisScale } = useChartYAxis(
    dimensions,
    data,
    axisTickSize * 4
  );
  const [showTarget, setShowTarget] = useState(false);
  const xAxisScale = scaleBand<Date>()
    .domain(data.map((d) => d.label))
    .rangeRound([60, chartWidth - 60]);
  const leftOffset = xAxisScale.bandwidth() / 2;

  return (
    <>
      <OutpuByTimeLegend
        showTarget={showTarget}
        setShowTarget={setShowTarget}
      />
      <BasicChartWrapper
        xAxisTitle={xAxisTitle}
        yAxisTitle={yAxisTitle}
        yValueFormatter={yValueFormatter}
        yAxisScale={yAxisScale}
        chartHeight={chartHeight}
        chartWidth={chartWidth}
        containerWidth={dimensions.width}
        containerHeight={dimensions.height}
        tooltipSlot={
          <OutputByTimeTooltip
            data={data}
            xAxisScale={xAxisScale}
            chartHeight={chartHeight}
            showTarget={showTarget}
            yValueFormatter={yValueFormatter}
          />
        }
      >
        {showTarget && (
          <Group className="text-brand-neutral" left={leftOffset}>
            <DataLine
              dataKey="target"
              xAxisScale={xAxisScale}
              yAxisScale={yAxisScale}
              data={data}
              chartHeight={chartHeight}
              showValues={false}
              futurePointsOpacity={1}
              dashed
            />
          </Group>
        )}
        <Group className="text-brand-blue-1" left={leftOffset}>
          <DataLine
            dataKey="value"
            xAxisScale={xAxisScale}
            yAxisScale={yAxisScale}
            data={data}
            chartHeight={chartHeight}
          />
        </Group>
        <Group top={chartHeight} left={leftOffset}>
          <AxisBottom
            xAxisScale={xAxisScale}
            xLabelFormatter={xLabelFormatter}
          />
        </Group>
      </BasicChartWrapper>
    </>
  );
}

function OutpuByTimeLegend({
  showTarget,
  setShowTarget,
}: {
  showTarget: boolean;
  setShowTarget: (checked: boolean) => void;
}) {
  const { i18n } = useLingui();
  return (
    <div className="px-12 pt-6 flex justify-between">
      <div className="flex items-center space-x-2">
        <Label htmlFor="show-target">
          {i18n.t("cycleCountTargetPrediction")}
        </Label>
        <Switch
          id="show-target"
          checked={showTarget}
          onCheckedChange={setShowTarget}
        />
      </div>
      {showTarget && (
        <ul className="flex gap-6">
          <li className="inline-flex items-center gap-2">
            <span className="rounded-full h-3 w-3 bg-brand-neutral block" />
            <span>{i18n.t("cycleCountTarget")}</span>
          </li>
          <li className="inline-flex items-center gap-2">
            <span className="rounded-full h-3 w-3 bg-brand-blue-1 block" />
            <span>{i18n.t("cycleCountActual")}</span>
          </li>
        </ul>
      )}
    </div>
  );
}

function useNow() {
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 10 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);
  return now;
}

function OutputByTimeTooltip({
  data,
  xAxisScale,
  chartHeight,
  showTarget,
  yValueFormatter = (value) => `${value}`,
}: {
  data: Array<DataPoint<Date>>;
  chartHeight: number;
  xAxisScale: ScaleBand<Date>;
  showTarget: boolean;
  yValueFormatter?: (value: number) => string;
}) {
  const { i18n } = useLingui();
  const now = useNow();
  const nowPoint = useMemo(() => {
    const pointIdx = data.findIndex((d) => d.label >= now);
    return pointIdx > 0 ? data[pointIdx - 1] : undefined;
  }, [data, now]);
  const [selectedPoint, setSelectedPoint] = useState<
    DataPoint<Date> | undefined
  >(nowPoint);
  const leftOffset = xAxisScale.bandwidth() / 2;
  const nowPointPosX = nowPoint ? (xAxisScale(nowPoint.label) ?? 0) : 0;
  const [tooltipProps, setTooltipProps] = useSpring(() => ({
    opacity: nowPoint ? 1 : 0,
    x: nowPointPosX,
  }));

  return (
    <>
      {selectedPoint && (
        <animated.div
          className="absolute z-20 pointer-events-none"
          style={{
            transform: tooltipProps.x.to(
              (x) => `translate(${x + leftOffset}px, 60px)`
            ),
          }}
        >
          <div
            className={cn(
              "border-solid border-t-white border-t-8 border-b-0",
              "border-x-transparent border-x-8",
              "h-4 w-4 -translate-x-1/2 absolute z-10 -bottom-4"
            )}
          />
          <div
            className={cn(
              "bg-white shadow-lg p-4 rounded-lg",
              "-translate-x-1/2 whitespace-nowrap",
              ""
            )}
          >
            {showTarget && (
              <p className="flex justify-between gap-6">
                <span>{i18n.t("cycleCountTarget")}:</span>
                <span className="font-bold">
                  {yValueFormatter(selectedPoint.value["target"] ?? 0)}
                </span>
              </p>
            )}
            <p className="flex justify-between gap-6">
              <span>{i18n.t("cycleCountActual")}:</span>
              <span className="font-bold">
                {yValueFormatter(selectedPoint.value["value"] ?? 0)}
              </span>
            </p>
          </div>
        </animated.div>
      )}
      <animated.div
        className="bg-brand-blue-1 absolute z-10 pointer-events-none"
        style={{
          width: 2,
          height: chartHeight,
          transform: tooltipProps.x.to(
            (x) => `translate(${x + leftOffset}px, 0px)`
          ),
          opacity: tooltipProps.opacity,
        }}
      />
      {data.map((d) => {
        return (
          <div
            key={d.label.toJSON()}
            style={{
              width: xAxisScale.bandwidth(),
              height: chartHeight,
              position: "absolute",
              left: xAxisScale(d.label) ?? 0,
              top: 0,
            }}
            onMouseEnter={() => {
              setSelectedPoint(d);
              setTooltipProps({ x: xAxisScale(d.label) ?? 0, opacity: 1 });
            }}
            onMouseLeave={() => {
              setSelectedPoint(nowPoint);
              setTooltipProps({ x: nowPointPosX, opacity: nowPoint ? 1 : 0 });
            }}
          />
        );
      })}
    </>
  );
}

function AxisBottom({
  xLabelFormatter = (value) => value.toISOString(),
  xAxisScale,
}: {
  xLabelFormatter?: (value: Date) => string;
  xAxisScale: ScaleBand<Date>;
}) {
  const axisStyle = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { mass: 10 },
  });
  return (
    <>
      {xAxisScale.domain().map((date) => {
        const x = xAxisScale(date) ?? 0;
        return (
          <animated.g key={date.toJSON()} opacity={axisStyle.opacity}>
            <line
              x1={x}
              x2={x}
              y1={0}
              y2={12}
              strokeWidth={1}
              stroke="currentColor"
              className="text-brand-gray-2"
            />
            <text
              x={x}
              y={axisTextOffset}
              fontSize={axisLabelFontSize}
              alignmentBaseline="text-before-edge"
              textAnchor="middle"
              className="text-brand-gray-4"
            >
              {xLabelFormatter(date)}
            </text>
          </animated.g>
        );
      })}
    </>
  );
}

type TimeDataPoint = {
  y: number;
  x: number;
  label: Date;
  value: number;
};

function DataLine({
  xAxisScale,
  yAxisScale,
  dataKey,
  data,
  chartHeight,
  showValues = true,
  dashed = false,
  futurePointsOpacity = 0.25,
  yValueFormatter = (value) => `${value}`,
}: {
  xAxisScale: ScaleBand<Date>;
  yAxisScale: ScaleLinear<number, number, never>;
  dataKey: string;
  data: Array<DataPoint<Date>>;
  chartHeight: number;
  showValues?: boolean;
  dashed?: boolean;
  futurePointsOpacity?: number;
  yValueFormatter?: (value: number) => string;
}) {
  const now = new Date();
  const pastPoints: Array<TimeDataPoint> = [];
  const futurePoints: Array<TimeDataPoint> = [];

  for (const item of data) {
    const label = item.label;
    const value = item.value[dataKey] ?? 0;
    const point = {
      label,
      value,
      y: yAxisScale(value) ?? 0,
      x: xAxisScale(item.label) ?? 0,
    };
    if (point.label < now) {
      pastPoints.push(point);
      // lets add last past point as the first future point
      futurePoints[0] = point;
    } else {
      futurePoints.push(point);
    }
  }

  const line = d3line<TimeDataPoint>()
    .x((d) => xAxisScale(d.label) ?? 0)
    .y((d) => yAxisScale(d.value) ?? 0)
    .curve(d3curve);

  const pastLineFrom = line(pastPoints.map((d) => ({ ...d, value: 0 }))) ?? "";
  const pastLineTo = line(pastPoints) ?? "";
  const futureLineFrom =
    line(futurePoints.map((d) => ({ ...d, value: 0 }))) ?? "";
  const futureLineTo = line(futurePoints) ?? "";

  return (
    <Group>
      {showValues && (
        <LineValues
          data={pastPoints}
          chartHeight={chartHeight}
          yValueFormatter={yValueFormatter}
        />
      )}
      <LinePath
        key={pastLineTo}
        pathFrom={pastLineFrom}
        pathTo={pastLineTo}
        opacity={1}
        dashed={dashed}
      />
      {futurePoints.length > 1 && (
        <LinePath
          key={futureLineTo}
          pathFrom={futureLineFrom}
          pathTo={futureLineTo}
          opacity={futurePointsOpacity}
          dashed={dashed}
        />
      )}
    </Group>
  );
}

function LineValues({
  data,
  chartHeight,
  yValueFormatter = (value) => `${value}`,
}: {
  chartHeight: number;
  yValueFormatter?: (value: number) => string;
  data: Array<TimeDataPoint>;
}) {
  const valuesTransition = useTransition<
    TimeDataPoint,
    { y: number; x: number; opacity: number; r: number }
  >(data, {
    keys: (d) => d.label.toJSON(),
    from: ({ x }) => ({ x, y: chartHeight, opacity: 0, r: 0 }),
    enter: ({ x, y }) => ({ x, y, opacity: 1, r: 6 }),
    leave: ({ x }) => ({ y: 0, x, opacity: 0, r: 6 }),
    update: ({ x, y }) => ({ x, y, opacity: 1, r: 6 }),
    config: {
      mass: 1,
      tension: 144,
      friction: 16,
    },
    trail: 16,
  });

  return (
    <>
      {valuesTransition((style, d) => {
        return (
          <Group key={`point-${d.label.toJSON()}`}>
            <animated.circle
              fill="currentColor"
              className="text-brand-chart-value"
              cx={style.x}
              cy={style.y}
              opacity={style.opacity}
              r={style.r}
            />
            <animated.text
              className="text-brand-black font-semibold"
              x={style.x}
              y={style.y.to((it) => it - axisTextOffset)}
              opacity={style.opacity}
              fontSize={axisLabelFontSize}
              alignmentBaseline="middle"
              textAnchor="middle"
              stroke="white"
              strokeWidth="6"
              paintOrder="stroke"
            >
              {yValueFormatter(d.value)}
            </animated.text>
          </Group>
        );
      })}
    </>
  );
}

function LinePath({
  pathFrom,
  pathTo,
  opacity,
  dashed,
}: {
  pathFrom: string;
  pathTo: string;
  opacity: number;
  dashed: boolean;
}) {
  const springProps = useSpring({
    from: { opacity: 0, path: pathFrom },
    to: { opacity, path: pathTo },
    config: {
      mass: 1,
      tension: 1500,
      friction: 100,
    },
    delay: 250,
  });

  return (
    <animated.path
      d={springProps.path}
      opacity={springProps.opacity}
      fill="none"
      stroke="currentColor"
      strokeWidth="3"
      strokeDasharray={dashed ? "4 8" : undefined}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}

function useChartYAxis(
  dimensions: Dimensions,
  data: Array<{ value: Record<string, number> }>,
  minItemWidth: number,
  defaultMaxValue?: number
) {
  const minRequiredWidth = data.length * minItemWidth;
  const availableWidth = Math.floor(dimensions.width - marginLeft);
  const requiredWidth = Math.max(availableWidth, minRequiredWidth);
  const chartWidth = requiredWidth;
  const chartHeight = dimensions.height - marginBottom - marginTop;
  const maxValue = useMemo(() => {
    return (
      Math.max(
        ...data.map((d) => Math.max(...Object.values(d.value))),
        defaultMaxValue ?? 10
      ) * 1.2
    ); // max value +20% padding
  }, [data, defaultMaxValue]);

  const yAxisDomain = [0, maxValue];
  const yAxisScale = scaleLinear()
    .domain(yAxisDomain)
    .range([chartHeight, axisTextOffset]);

  return {
    yAxisScale,
    chartWidth,
    chartHeight,
  };
}

function BasicChartWrapper({
  xAxisTitle,
  yAxisTitle,
  yValueFormatter = (value) => `${value}`,
  yAxisScale,
  chartHeight,
  chartWidth,
  containerHeight,
  containerWidth,
  children,
  tooltipSlot = null,
}: PropsWithChildren<{
  xAxisTitle: string;
  yAxisTitle: string;
  chartHeight: number;
  chartWidth: number;
  containerHeight: number;
  containerWidth: number;
  yValueFormatter?: (value: number) => string;
  yAxisScale: ScaleLinear<number, number, never>;
  tooltipSlot?: React.ReactNode;
}>) {
  const axisStyle = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { mass: 10 },
  });
  const yAxisMiddle = (chartHeight + marginTop) / 2;
  const ticks = yAxisScale.ticks(4).map((value) => {
    const yOffset = yAxisScale(value) ?? 0;
    const label = yValueFormatter(value);
    return { yOffset, label, value };
  });

  return (
    <div className="relative py-6">
      <svg
        className="relative z-10 bg-gradient-to-r from-white from-30% to-80% to-white/0"
        height={containerHeight}
        width={marginLeft}
      >
        <animated.g
          style={axisStyle}
          strokeWidth={1}
          stroke="currentColor"
          className="text-brand-gray-2 absolute left-0"
        >
          <text
            className="text-brand-gray-5"
            x={axisTextOffset}
            y={yAxisMiddle + (axisTextOffset * 3) / 2}
            fontSize={axisLabelFontSize}
            alignmentBaseline="middle"
            textAnchor="middle"
            transform={`rotate(-90, ${axisTextOffset}, ${yAxisMiddle})`}
            stroke="white"
            strokeWidth="4"
            paintOrder="stroke"
          >
            {yAxisTitle}
          </text>
          {ticks.map(({ yOffset, label, value }) => (
            <Group key={`axis-left-${value}`} top={yOffset + marginTop}>
              <text
                className="text-brand-gray-4"
                x={marginLeft - axisTextOffset / 2}
                fontSize={axisLabelFontSize}
                alignmentBaseline="middle"
                textAnchor="end"
                stroke="white"
                strokeWidth="4"
                paintOrder="stroke"
              >
                {label}
              </text>
            </Group>
          ))}
        </animated.g>
      </svg>
      <div className="absolute inset-0 top-6">
        <svg className="w-full" height={containerHeight}>
          <animated.g
            style={axisStyle}
            strokeWidth={1}
            stroke="currentColor"
            className="text-brand-gray-2"
          >
            <Group left={marginLeft} top={marginTop}>
              <text
                className="text-brand-gray-5"
                x={(containerWidth - marginLeft) / 2}
                y={chartHeight + axisTextOffset * 5}
                fontSize={axisLabelFontSize}
                alignmentBaseline="text-after-edge"
                textAnchor="middle"
                stroke="white"
                strokeWidth="4"
                paintOrder="stroke"
              >
                {xAxisTitle}
              </text>
              {ticks.map(({ yOffset, value }) => (
                <Group
                  key={`grid-${value}`}
                  top={yOffset}
                  strokeWidth={1}
                  stroke="currentColor"
                  className="text-brand-gray-2"
                >
                  <line x2={chartWidth + marginLeft} />
                </Group>
              ))}
            </Group>
          </animated.g>
        </svg>
      </div>
      <div className="absolute inset-0 top-6 overflow-auto">
        <svg className="h-full min-w-full" width={chartWidth + marginLeft}>
          <Group left={marginLeft} top={marginTop}>
            {children}
          </Group>
        </svg>
        {tooltipSlot && (
          <div
            className="absolute inset-0 overflow-hidden"
            style={{ left: marginLeft, top: marginTop, width: chartWidth }}
          >
            {tooltipSlot}
          </div>
        )}
      </div>
    </div>
  );
}

export function CycleVarianceChart({
  xAxisTitle,
  yAxisTitle,
  yValueFormatter = (value) => `${value}`,
  data,
  target: realTarget,
  mean,
  dimensions,
  onBarClick,
}: {
  xAxisTitle: string;
  yAxisTitle: string;
  yValueFormatter?: (value: number) => string;
  data: Array<{
    label: [number, number];
    value: number;
  }>;
  target: number;
  mean: number;
  dimensions: Dimensions;
  onBarClick?: (value: [number, number]) => void;
}) {
  const { i18n } = useLingui();
  const { chartWidth, chartHeight, yAxisScale } = useChartYAxis(
    dimensions,
    data.map((d) => ({ ...d, value: { value: d.value } })),
    axisTickSize * 3
  );

  function getMiddleValue(value: [number, number]) {
    const [start, end] = value;
    return Math.round((start + end) / 2);
  }

  const domain = data.map((d) => getMiddleValue(d.label));
  const xAxisScale = scaleLinear()
    .domain([Math.min(...domain), Math.max(...domain)])
    .rangeRound([axisTickSize * 4, chartWidth - axisTickSize * 4]);

  const [xStart, xEnd] = xAxisScale.range();
  const bandWidth = Math.floor((xEnd - xStart) / domain.length);
  const barWidth = Math.max(minBarWidth, Math.min(maxBarWidth, bandWidth));
  // const meanPosX = xAxisScale(mean) ?? 0;

  const bars = data.map((d) => ({
    range: d.label,
    middle: getMiddleValue(d.label),
    value: d.value,
    x: (xAxisScale(getMiddleValue(d.label)) ?? 0) - barWidth / 2,
    y: yAxisScale(d.value) ?? 0,
  }));

  const axisStyle = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { mass: 10 },
  });

  const transition = useTransition<
    (typeof bars)[number],
    { y: number; x: number; opacity: number }
  >(bars, {
    keys: (d) => d.middle,
    from: ({ x }) => ({ x, y: chartHeight, opacity: 0 }),
    enter: ({ x, y }) => ({ x, y, opacity: 1 }),
    leave: ({ x, y }) => ({ x: x - bandWidth / 2, y, opacity: 0 }),
    update: ({ x, y }) => ({ x, y, opacity: 1 }),
    config: {
      mass: 1,
      tension: 1500,
      friction: 100,
    },
    trail: 24,
  });

  return (
    <BasicChartWrapper
      xAxisTitle={xAxisTitle}
      yAxisTitle={yAxisTitle}
      yValueFormatter={yValueFormatter}
      yAxisScale={yAxisScale}
      chartHeight={chartHeight}
      chartWidth={chartWidth}
      containerWidth={dimensions.width}
      containerHeight={dimensions.height}
    >
      {/* slow fast xAxis line */}
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#0a5" />
          <stop offset="100%" stopColor="#f00" />
        </linearGradient>
      </defs>
      <animated.g transform={`translate(0, ${chartHeight})`}>
        <animated.text
          y={axisTickSize}
          fontSize={10}
          fontWeight="bold"
          alignmentBaseline="middle"
          opacity={axisStyle.opacity}
        >
          {i18n.t("cycleVarianceAxisFast")}
        </animated.text>
        <animated.text
          x={chartWidth}
          y={axisTickSize}
          fontSize={10}
          fontWeight="bold"
          textAnchor="end"
          alignmentBaseline="middle"
          opacity={axisStyle.opacity}
        >
          {i18n.t("cycleVarianceAxisSlow")}
        </animated.text>
        <animated.rect
          width={chartWidth}
          height={4}
          fill="url(#gradient)"
          opacity={axisStyle.opacity}
        />
      </animated.g>
      {/* slow fast xAxis line */}
      {/* Mean - removed due to issues with proper visualisation, this chart needs to be reworked */}
      {/* <animated.line
        x1={meanPosX}
        x2={meanPosX}
        y1={0}
        y2={chartHeight}
        stroke="currentColor"
        strokeWidth="2"
        strokeDasharray="8 8"
        className="text-brand-blue-1"
        opacity={axisStyle.opacity}
      />
      <animated.text
        x={meanPosX}
        y={axisTickSize * 2}
        fontSize={12}
        textAnchor="middle"
        fill="currentColor"
        stroke="white"
        strokeWidth="4"
        paintOrder="stroke"
        className="text-brand-blue-1"
        opacity={axisStyle.opacity}
      >
        {i18n.t("cycleVarianceAnnotationMean")}
      </animated.text> */}
      {/* Mean */}
      {transition((style, d) => {
        return (
          <animated.g key={d.middle}>
            <animated.rect
              x={style.x}
              y={style.y}
              width={barWidth}
              height={style.y.to((it) => Math.max(0, chartHeight - it))}
              opacity={style.opacity}
              rx={3}
              fill="currentColor"
              className="text-brand-blue-1 hover:text-brand-blue-2 cursor-pointer"
              onClick={() => onBarClick?.(d.range)}
            />
          </animated.g>
        );
      })}
      {transition((style, d) => {
        const [start, end] = d.range;
        const target = realTarget > 0 ? realTarget : mean;
        const targetBetween = target >= start && target <= end;
        const value = target > d.middle ? target - d.middle : d.middle - target;
        const label = formatCycleTimeDifference(value);
        const sign = target > d.middle ? "-" : "+";

        return (
          <animated.g key={d.middle}>
            <animated.g
              opacity={style.opacity}
              transform={to(
                [style.x, style.y],
                (x, y) =>
                  `translate(${x + barWidth / 2}, ${y - axisTextOffset})`
              )}
            >
              <TruncatedText
                maxWidth={Math.max(axisTextOffset * 3.5, bandWidth)}
                alignmentBaseline="middle"
                className="text-brand-black font-semibold pointer-events-none"
              >
                {yValueFormatter(d.value)}
              </TruncatedText>
            </animated.g>
            <Group
              strokeWidth={1}
              stroke="currentColor"
              className="text-brand-gray-2"
            >
              <animated.line
                x1={style.x.to((it) => it + barWidth / 2)}
                x2={style.x.to((it) => it + barWidth / 2)}
                y1={chartHeight}
                y2={chartHeight + axisTickSize}
                opacity={style.opacity}
              />
              <animated.g
                opacity={style.opacity}
                transform={style.x.to(
                  (x) =>
                    `translate(${x + barWidth / 2}, ${
                      chartHeight + axisTextOffset
                    })`
                )}
              >
                <TruncatedText
                  maxWidth={bandWidth - 4}
                  className={cn({
                    "font-semibold text-brand-black": targetBetween,
                  })}
                >
                  {targetBetween
                    ? i18n.t("cycleVarianceMedianTarget")
                    : `${sign}${label}`}
                </TruncatedText>
              </animated.g>
            </Group>
          </animated.g>
        );
      })}
    </BasicChartWrapper>
  );
}
