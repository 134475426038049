import { I18n } from "@lingui/core";

import { CycleStatsByStation } from "@/domain/statistics";
import { TagId, TagStore } from "@/domain/tag";

import { ChartType } from "./chart-type-select";
export type DataType = "cycleCount" | "cycleTime" | "activity";

export function getLegendName(
  key: string,
  chartType: ChartType,
  dataType: DataType,
  tagsStore: TagStore,
  i18n: I18n
) {
  if (
    dataType !== "activity" &&
    chartType === "compared" &&
    !key.endsWith("_value")
  ) {
    return tagsStore.tagsById[key as TagId]?.name ?? key;
  }
  switch (key) {
    case "count_value":
      return i18n.t("Cycle count");
    case "time_value":
      return i18n.t("Average cycle time");
    case "count_intersection":
      return i18n.t("Intersection");
    case "count_difference":
      return i18n.t("Difference");
    case "person_product":
      return i18n.t("Person & product at station");
    case "person":
      return i18n.t("Only person at station");
    case "product":
      return i18n.t("Only product at station");
    case "empty":
      return i18n.t("No person & no product");
    default:
      return key;
  }
}

export function getColor(
  key: string,
  chartType: ChartType,
  dataType: DataType,
  tagsStore: TagStore
) {
  if (dataType === "activity") {
    return getColorForActivity(key, chartType);
  }
  if (chartType === "compared" && !key.endsWith("_value")) {
    return tagsStore.tagsById[key as TagId]?.color ?? "#148CFF";
  }
  switch (key) {
    case "count_difference":
      return "#CFDBE7";
    case "count_value":
    case "time_value":
    case "count_intersection":
    default:
      return "#148CFF";
  }
}

function getColorForActivity(key: string, chartType: ChartType) {
  if (chartType === "single") {
    return "#148CFF";
  }
  switch (key) {
    case "empty":
      return "#BECADA"; // gray
    case "person":
      return "#e2553f"; // red
    case "product":
      return "#eb942e"; // orange
    case "person_product":
    default:
      return "#4faf6e";
  }
}

export function getKeysForChartType(
  dataType: DataType,
  chartType: ChartType,
  selectedTagIds: Array<TagId>
) {
  if (dataType === "cycleCount") {
    if (chartType === "combined") {
      return ["count_intersection", "count_difference"];
    } else if (chartType === "compared") {
      return ["count_value", ...selectedTagIds];
    } else {
      return ["count_value"];
    }
  }
  if (dataType === "cycleTime") {
    if (chartType === "compared") {
      return ["time_value", ...selectedTagIds];
    } else {
      return ["time_value"];
    }
  }
  if (dataType === "activity") {
    return ["person_product", "person", "product", "empty"];
  }
  return [];
}

export function getValueForChartType<T extends CycleStatsByStation>(
  chartType: ChartType,
  cycleTimeByStation: T,
  hiddenLegends: Array<string>
) {
  return {
    stationId: cycleTimeByStation.stationId,
    ...Object.fromEntries(
      cycleTimeByStation[chartType]
        .filter((it) => !hiddenLegends.includes(it.key))
        .map((it) => [it.key, it.value])
    ),
  };
}
