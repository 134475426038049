import { z } from "zod";

import { kpiMetric } from "./metrics";

export const entitySchema = z.object({
  id: z.string(),
  name: z.string(),
  shortName: z.string(),
});

export const entityWithMetricsSchema = entitySchema.extend({
  metrics: z.array(kpiMetric),
});

export type Entity = z.infer<typeof entitySchema>;
export type EntityWithMetrics = z.infer<typeof entityWithMetricsSchema>;
