import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { DateRangeFilter } from "@/domain/common/time-filter";
import { LineWithStations } from "@/domain/levels";
import { ShiftId } from "@/domain/shifts";
import { Station } from "@/domain/station";
import { useAnalytics } from "@/view/providers/analytics-provider";

import { DateRangeSelect } from "../line-id/date-range-select";
import {
  SelectedLineContext,
  useSelectedLine,
} from "../line-id/selected-line-provider";
import { ShiftSelect } from "../line-id/shift-select";
import {
  encodeDateRangeToSearchParams,
  parseDateRangeFromSearchParams,
  SelectedDateRangeContext,
} from "../line-id/use-selected-date-range";
import {
  encodeShiftIdsToSearchParams,
  parseShiftIdsFromSearchParams,
  SelectedShiftIdsContext,
} from "../line-id/use-selected-shift-ids";
import { StationSelect } from "./station-select";
import {
  encodeStationIdToSearchParams,
  parseStationIdFromSearchParams,
  SelectedStationContext,
} from "./use-selected-station";

type TrendsFilters = {
  line: LineWithStations;
  shiftIds: Array<ShiftId>;
  station: Station | null;
  dateRange: DateRangeFilter;
};

type TrendsFilterActions = {
  selectShifts: (shiftIds: Array<ShiftId>) => void;
  selectDateRange: (dateRange: DateRangeFilter) => void;
  selectStation: (station: Station | null) => void;
};

const TrendsFilterActionsContext = createContext<TrendsFilterActions | null>(
  null
);

export function TrendsFiltersProvider({ children }: PropsWithChildren) {
  const line = useSelectedLine();
  const analytics = useAnalytics();
  const [state, setState] = useState<TrendsFilters>(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return {
      line,
      shiftIds: parseShiftIdsFromSearchParams(searchParams, line)!,
      dateRange: parseDateRangeFromSearchParams(searchParams),
      station: parseStationIdFromSearchParams(searchParams, line),
    };
  });

  useEffect(() => {
    // synchronise with lineId from the URL
    if (state.line.id === line.id) return;
    setState((prev) => {
      const shiftIds = line.shifts.map((it) => it.id);
      const station = null;
      return { ...prev, line, shiftIds, station };
    });
  }, [line, state.line.id]);

  // TODO: turn this into a generic "useSynchronizeUrl" custom hook
  // when the "useEffectEvent" hook is released in a stable version of React
  // => https://react.dev/learn/separating-events-from-effects#declaring-an-effect-event
  useEffect(() => {
    // synchronise with URL
    let searchParams = new URLSearchParams();
    searchParams = encodeShiftIdsToSearchParams(searchParams, state.shiftIds);
    searchParams = encodeDateRangeToSearchParams(searchParams, state.dateRange);

    if (state.station) {
      searchParams = encodeStationIdToSearchParams(searchParams, state.station);
    }

    window.history.replaceState(
      null,
      "",
      `${window.location.pathname}?${searchParams}`
    );

    // send analytics event
    analytics.collect("filters_changed");
  }, [state, analytics]);

  const selectedLine = useMemo(() => state.line, [state.line]);
  const selectedShiftIds = useMemo(() => state.shiftIds, [state.shiftIds]);
  const selectedDateRange = useMemo(() => state.dateRange, [state.dateRange]);
  const station = useMemo(() => state.station, [state.station]);
  const actions = useMemo<TrendsFilterActions>(() => {
    return {
      selectShifts: (shiftIds: Array<ShiftId>) => {
        setState((prev) => ({ ...prev, shiftIds }));
      },
      selectDateRange: (dateRange: DateRangeFilter) => {
        setState((prev) => ({ ...prev, dateRange }));
      },
      selectStation: (station: Station | null) => {
        setState((prev) => ({ ...prev, station }));
      },
    };
  }, []);

  return (
    // provide the line context again, so that the "selectedLine" comes
    // from state value, which prevents double rerenders caused by line change
    <SelectedLineContext.Provider value={selectedLine}>
      <SelectedShiftIdsContext.Provider value={selectedShiftIds}>
        <SelectedDateRangeContext.Provider value={selectedDateRange}>
          <SelectedStationContext.Provider value={station}>
            <TrendsFilterActionsContext.Provider value={actions}>
              {children}
            </TrendsFilterActionsContext.Provider>
          </SelectedStationContext.Provider>
        </SelectedDateRangeContext.Provider>
      </SelectedShiftIdsContext.Provider>
    </SelectedLineContext.Provider>
  );
}

function useTrendsFilterActions() {
  const actions = useContext(TrendsFilterActionsContext);
  if (!actions) {
    throw new Error(
      "useTrendsFilterActions must be used inside TrendsFilterActionsProvider"
    );
  }
  return actions;
}

export function TrendsFilters() {
  const actions = useTrendsFilterActions();
  return (
    <>
      <StationSelect onChange={actions.selectStation} />
      <ShiftSelect onChange={actions.selectShifts} />
      <DateRangeSelect onChange={actions.selectDateRange} />
    </>
  );
}
