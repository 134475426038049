import { useLingui } from "@lingui/react";
import { useMemo } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { StationId } from "@/domain/station";
import bgPatternImg from "@/view/assets/bg-pattern.png";
import searchCloudImg from "@/view/assets/search-cloud.png";
import { CardContent, TabsContent } from "@/view/components";
import { OutputByTimeChart } from "@/view/components/charts";
import { useElementSize } from "@/view/providers/use-element-size";
import { paths } from "@/view/routes";
import { formatDateForTimeAxis } from "@/view/utils";

import { useSelectedLine } from "../line-id/selected-line-provider";
import {
  encodeDateRangeToSearchParams,
  useSelectedDateRange,
} from "../line-id/use-selected-date-range";
import {
  encodeShiftIdsToSearchParams,
  useSelectedShiftIds,
} from "../line-id/use-selected-shift-ids";
import { useSelectedStationIds } from "../line-id/use-selected-station-ids";
import { ActivityByStationChart } from "./charts/activity-by-station-chart";
import { CycleCountByStationChart } from "./charts/cycle-count-by-station-chart";
import { CycleTimeByStationChart } from "./charts/cycle-time-by-station-chart";
import { useLineStatistics } from "./line-statistics-provider";

export function LineReportingChartsLoading() {
  const shimmers = useMemo(() => {
    return Array.from({ length: 12 }).map((_, i) => ({
      key: i,
      height: `${Math.floor(Math.random() * 300) + 100}px`,
    }));
  }, []);
  return (
    <CardContent className="grow flex items-end justify-center gap-12 pt-24">
      {shimmers.map((it) => (
        <div
          key={it.key}
          className="w-full max-w-[4rem] animate-pulse bg-brand-gray-2 rounded-md"
          style={{ height: it.height }}
        />
      ))}
    </CardContent>
  );
}

/**
 * @todo replace with ChartErrorStatus component
 */
export function ChartsErrorStatus({
  title,
  description,
}: {
  title?: string;
  description?: string;
}) {
  const { i18n } = useLingui();
  return (
    <CardContent
      className="grow flex items-center justify-center gap-12 pt-24 min-h-[540px] bg-no-repeat bg-center"
      style={{ backgroundImage: `url(${bgPatternImg})` }}
    >
      <div className="text-center max-w-md">
        <img src={searchCloudImg} alt="" className="mx-auto mb-8" />
        <h3 className="text-xl font-bold text-brand-black">
          {title ?? i18n.t("lineOverviewChartErrorTitle")}
        </h3>
        <p>{description ?? i18n.t("lineOverviewChartErrorDescription")}</p>
      </div>
    </CardContent>
  );
}

// TODO: refactor this component at some point
export function LineStatistics() {
  const navigate = useNavigate();
  const { i18n } = useLingui();
  const { ref, width } = useElementSize();
  const data = useLineStatistics();
  const shiftIds = useSelectedShiftIds();
  const stationIds = useSelectedStationIds();
  const line = useSelectedLine();
  const dateRange = useSelectedDateRange();

  const heightMin = 480;
  const heightMax = 580;
  const height = Math.max(heightMin, Math.min(heightMax, (width * 8) / 16));

  const cycleCountByStation = useMemo(() => {
    const result = [];
    for (const stationId of stationIds) {
      const dataByStation = data.cycleCountByStationId[stationId];
      if (dataByStation) {
        result.push(dataByStation);
      }
    }
    return result;
  }, [data, stationIds]);
  const cycleTimeByStation = useMemo(() => {
    const result = [];
    for (const stationId of stationIds) {
      const dataByStation = data.cycleTimeByStationId[stationId];
      if (dataByStation) {
        result.push(dataByStation);
      }
    }
    return result;
  }, [data, stationIds]);
  const activityByStation = useMemo(() => {
    const result = [];
    for (const stationId of stationIds) {
      const dataByStation = data.activityByStationId[stationId];
      if (dataByStation) {
        result.push(dataByStation);
      }
    }
    return result;
  }, [data, stationIds]);
  const cycleCountByTime = useMemo(() => {
    return data.cycleCountByTime.reduce(
      (acc, it) => {
        acc.push({
          label: it.date,
          value: {
            target: it.accTarget,
            value: Number(it.accValue.toFixed(2)),
          },
        });
        return acc;
      },
      [] as Array<{ label: Date; value: Record<string, number> }>
    );
  }, [data]);

  function onStationSelect(stationId: StationId) {
    let searchParams = new URLSearchParams();
    searchParams = encodeShiftIdsToSearchParams(searchParams, shiftIds);
    searchParams = encodeDateRangeToSearchParams(searchParams, dateRange);
    navigate(
      generatePath(paths.lineReportingStationDetailsPath, {
        lineId: line.id,
        stationId,
      }) + `?${searchParams}`
    );
  }

  function hasData(data: Record<string, unknown>) {
    return Object.keys(data).length > 0;
  }

  return (
    <CardContent ref={(el) => (ref.current = el)} className="grow h-full p-0">
      {width > 0 && (
        <>
          <TabsContent value="output">
            {hasData(data.cycleCountByStationId) ? (
              <CycleCountByStationChart
                height={height}
                data={cycleCountByStation}
                onBarClick={onStationSelect}
              />
            ) : (
              <ChartsErrorStatus />
            )}
          </TabsContent>
          <TabsContent value="avg_cycle_time">
            {hasData(data.cycleTimeByStationId) ? (
              <CycleTimeByStationChart
                height={height}
                data={cycleTimeByStation}
                onBarClick={onStationSelect}
              />
            ) : (
              <ChartsErrorStatus />
            )}
          </TabsContent>
          <TabsContent value="activity">
            {hasData(data.activityByStationId) ? (
              <ActivityByStationChart
                height={height}
                data={activityByStation}
                onBarClick={onStationSelect}
              />
            ) : (
              <ChartsErrorStatus />
            )}
          </TabsContent>
          <TabsContent value="output_by_time">
            {data.cycleCountByTime.length > 0 ? (
              <OutputByTimeChart
                xAxisTitle=""
                yAxisTitle={i18n.t("lineOverviewChartTabOutputByTime")}
                dimensions={{ width, height }}
                data={cycleCountByTime}
                xLabelFormatter={formatDateForTimeAxis(data.timeGranularity)}
              />
            ) : (
              <ChartsErrorStatus />
            )}
          </TabsContent>
        </>
      )}
    </CardContent>
  );
}
