import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { generatePath, Navigate, useParams } from "react-router-dom";

import { LineWithStations } from "@/domain/levels";
import { lineIdSchema } from "@/domain/line";
import { paths } from "@/view/routes";

import { useLevels } from "./levels-provider";

export const SelectedLineContext = createContext<LineWithStations | null>(null);

// eslint-disable-next-line react-refresh/only-export-components
export function useSelectedLine() {
  const selectedLine = useContext(SelectedLineContext);
  if (!selectedLine) {
    throw new Error("useSelectedLine must be used inside SelectedLineProvider");
  }
  return selectedLine;
}

/**
 * Provides the selected line down the component tree.
 *
 * It also validates if the lineId search param is valid
 * based on the available lines. If it's not valid, it
 * redirects to a default valid lineId.
 */
export function SelectedLineProvider({ children }: PropsWithChildren) {
  const levels = useLevels();
  const { lineId } = useParams<{ lineId: string }>();

  const selectedLine = useMemo(() => {
    const validatedId = lineIdSchema.safeParse(lineId);
    return validatedId.success
      ? (levels.linesByLineId[validatedId.data] ?? levels.defaultLine)
      : levels.defaultLine;
  }, [levels, lineId]);

  if (!selectedLine) {
    // This should never happen, but if so, we're logging it in Sentry
    // in LevelsProvider, so we can just return null here.
    return null;
  }

  if (selectedLine.id !== lineId) {
    const path = generatePath(paths.lineReportingPath, {
      lineId: selectedLine.id,
    });
    return <Navigate to={path} replace />;
  }

  return (
    <SelectedLineContext.Provider value={selectedLine}>
      {children}
    </SelectedLineContext.Provider>
  );
}
