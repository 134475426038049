import { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";

import {
  Card,
  CardContent,
  Tabs,
  TabsList,
  TabsTrigger,
} from "@/view/components";
import { LoadingStatus } from "@/view/components/loading-status";
import { useSelectedLine } from "@/view/pages/line-id/selected-line-provider";
import { useSelectedDateRange } from "@/view/pages/line-id/use-selected-date-range";
import { useSelectedShiftIds } from "@/view/pages/line-id/use-selected-shift-ids";
import { useTags } from "@/view/pages/line-id/use-tags";
import { ChartsErrorStatus } from "@/view/pages/line-id-reporting/line-statistics";
import { useSelectedStationDetails } from "@/view/pages/line-id-reporting-station-id/selected-station-provider";
import {
  useStationReportingFilterActions,
  VideosReportingFilters,
} from "@/view/pages/line-id-reporting-station-id/station-reporting-filters-provider";
import { useDurationRange } from "@/view/pages/line-id-video-library/use-duration-range";
import { usePagination } from "@/view/pages/line-id-video-library/use-pagination";
import {
  useSelectedVideo,
  useVideosQuery,
} from "@/view/pages/line-id-video-library/use-videos";
import {
  BookmarkVideoButton,
  VideoDescriptionForm,
  VideoDialog,
  VideoList,
  VideoListItem,
  VideoListPagination,
  VideoPlayer,
  VideoTagsForm,
  VideoTile,
} from "@/view/pages/line-id-video-library/videos";
import { cn } from "@/view/utils";

const videosTabsList = ["all", "best_practices", "slow_cycles"] as const;

type VideosTabs = (typeof videosTabsList)[number];

/**
 * @todo move this component to `features` folder and get rid of page dependencies
 */
export function StationVideos() {
  const { i18n } = useLingui();
  const actions = useStationReportingFilterActions();
  const [selectedTab, setSelectedTab] = useState<VideosTabs>("all");
  const selectedLine = useSelectedLine();
  const selectedStation = useSelectedStationDetails();
  const dateRange = useSelectedDateRange();
  const shiftIds = useSelectedShiftIds();
  const pagination = usePagination();
  const durationRange = useDurationRange();
  const tagsStore = useTags();
  const selectedSystemTag = useMemo(() => {
    return tagsStore.tagsByType.System.find(
      // this is very brittle, it revolves around hardcoded tab names
      (tag) => selectedTab === tag.name.toLowerCase().split(" ").join("_")
    );
  }, [tagsStore.tagsByType.System, selectedTab]);
  const videosQueryOptions = useVideosQuery({
    stationIds: [selectedStation.id],
    tagIds: selectedSystemTag ? [selectedSystemTag.id] : [],
    dateRange,
    shiftIds,
    durationRange,
    pagination,
  });
  const videosQuery = useQuery(videosQueryOptions);
  const { selectedVideoId, prevVideoId, nextVideoId, setSelectedVideoId } =
    useSelectedVideo(videosQuery.data?.data ?? []);
  const selectedVideo =
    videosQuery.data?.data.find((video) => video.id === selectedVideoId) ??
    null;
  const selectedVideoTags =
    selectedVideo?.tagIds.map((id) => tagsStore.tagsById[id]) ?? [];

  return (
    <>
      <div className="flex items-center gap-4 mb-4">
        <h3 className="grow text-lg font-semibold">
          {i18n.t("stationDetailsTabVideos")}
        </h3>
        <VideosReportingFilters />
      </div>
      <Card className="border">
        <Tabs
          value={selectedTab}
          onValueChange={(val) => {
            setSelectedTab(val as VideosTabs);
            // reset pagination when changing tabs
            actions.selectPage(1);
          }}
        >
          <TabsList className="gap-0.5 border rounded-lg bg-brand-gray-1 p-0.5 m-4">
            {videosTabsList.map((tab) => (
              <TabsTrigger
                key={tab}
                value={tab}
                className={cn(
                  "grow rounded-md px-3 py-2.5 border border-transparent",
                  "data-[state=active]:bg-brand-white",
                  "data-[state=active]:text-brand-black",
                  "data-[state=active]:border-brand-gray-2",
                  "data-[state=active]:shadow-sm"
                )}
              >
                {getVideosTabTitle(tab, i18n)}
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>
        <CardContent className="relative px-4 min-h-[640px] flex flex-col">
          {(videosQuery.isPending || videosQuery.isLoading) && (
            <LoadingStatus />
          )}
          {(videosQuery.isError ||
            (videosQuery.isSuccess && videosQuery.data.totalCount === 0)) && (
            <ChartsErrorStatus />
          )}
          {videosQuery.isSuccess && videosQuery.data.totalCount > 0 && (
            <div className="grow flex flex-col">
              <h4 className="text-sm mb-2">
                {i18n.t("stationVideosTabTotalCount", {
                  count: videosQuery.data.totalCount,
                })}
              </h4>
              <div className="grow flex flex-col">
                <VideoList minItemWidth={320} isLoading={videosQuery.isLoading}>
                  {videosQuery.data.data.map((video) => {
                    const tags = video.tagIds.map(
                      (id) => tagsStore.tagsById[id]
                    );

                    return (
                      <VideoListItem key={video.id}>
                        <VideoTile
                          video={video}
                          tags={tags}
                          onClick={() => setSelectedVideoId(video.id)}
                        />
                      </VideoListItem>
                    );
                  })}
                </VideoList>
                <VideoListPagination
                  isLoading={videosQuery.isFetching}
                  totalVideosCount={videosQuery.data.totalCount}
                  pagination={pagination}
                  onChangePage={actions.selectPage}
                />
              </div>
            </div>
          )}
          <VideoDialog
            open={selectedVideo !== null}
            title={i18n.t("videoSnippet", { id: selectedVideo?.id })}
            onPrev={
              prevVideoId && selectedVideo
                ? () => setSelectedVideoId(prevVideoId)
                : undefined
            }
            onNext={
              nextVideoId && selectedVideo
                ? () => setSelectedVideoId(nextVideoId)
                : undefined
            }
            onClose={() => setSelectedVideoId(null)}
          >
            {selectedVideo && (
              <>
                <section className="aspect-video bg-brand-gray-2">
                  <VideoPlayer video={selectedVideo} />
                </section>
                <section className="flex flex-col gap-4 px-4 py-6">
                  <VideoTagsForm
                    lineId={selectedLine.id}
                    video={selectedVideo}
                    videoTags={selectedVideoTags}
                  />
                  <VideoDescriptionForm video={selectedVideo} />
                  <div className="flex justify-center">
                    <BookmarkVideoButton video={selectedVideo} />
                  </div>
                </section>
              </>
            )}
          </VideoDialog>
        </CardContent>
      </Card>
    </>
  );
}

function getVideosTabTitle(tab: VideosTabs, i18n: I18n) {
  switch (tab) {
    case "all":
      return i18n.t("stationVideosTabAll");
    case "best_practices":
      return i18n.t("stationVideosTabBestPractices");
    case "slow_cycles":
      return i18n.t("stationVideosTabAllSlowCycles");
  }
}
